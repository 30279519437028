import {
    Autocomplete, Box, Button, Checkbox, Collapse, FormControl, FormControlLabel,
    IconButton, InputAdornment, InputLabel, MenuItem, Select, Tab, Table, TableBody, TableCell, TableHead,
    TableRow, TextField, Tooltip, Typography, TablePagination
} from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import Loader from '../../../Components/Loader';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ImagePaths } from '../../../Utility/ImagePaths';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { TimePicker as AntdTimePicker } from 'antd';
import moment from 'moment';
import { DeletePopup } from '../../../Components/Common Components/CommonComponents';
import SearchIcon from '@mui/icons-material/Search';
import { CheckAccessFunc, AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { CurrencySymbol, gstPercentage, OP_IP_CODE_LENGTH } from '../../../Utility/Constants';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { AddCircle } from '@material-ui/icons';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';

// const calInclusiveGstAmt = (percentage, amount) => {
//     const gstPercentage = Number(percentage || 0);
//     const baseAmount = Number(amount);

//     const gstAmount = (gstPercentage / 100) * baseAmount;
//     return parseFloat((baseAmount + gstAmount).toFixed(2));
// }

export default class OPNewServiceConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            serviceConfigData: [],
            selectedIndex: null,
            categoryData: [],
            serviceCategory: null,
            service_type: '',
            doctor_name: null,
            new_pat_total_amt: '',
            new_pat_doc_fee: '',
            new_pat_hos_fee: '',
            new_pat_doc_percent: '',
            new_pat_hos_percent: '',
            old_pat_total_amt: '',
            old_pat_doc_fee: '',
            old_pat_hos_fee: '',
            old_pat_doc_percent: '',
            old_pat_hos_percent: '',
            rev_pat_total_amt: '',
            rev_pat_doc_fee: '',
            rev_pat_hos_fee: '',
            rev_pat_doc_percent: '',
            rev_pat_hos_percent: '',
            holi_total_amt: '',
            holi_doc_fee: '',
            holi_hos_fee: '',
            holi_doc_percent: '',
            holi_hos_percent: '',
            eme_doc_total_amt: '',
            eme_doc_fee: '',
            eme_hos_fee: '',
            eme_doc_percent: '',
            eme_hos_percent: '',
            isEditUnit: false,
            isEditAmount: false,
            isEditDiscount: false,
            serviceNameList: [],
            selectedSymbol: 'rupee',
            selectedUnit: 'nos',
            service_amt: '',
            emergency_amt: '',
            holiday_amt: '',
            service_name: '',
            selectedTime: null,
            doctorList: [],
            isEdit: false,
            selectedId: null,
            serviceListEdit: false,
            otherServiceEdit: false,
            editSelectedIndex: null,
            duplicateServiceData: '[]',
            editIndex: null,
            doctor_id: null,
            otherServiceList: [],
            editServiceCategoryId: null,
            otherCategoryId: null,
            serviceId: null,
            deletePopup: false,
            deletedIds: [],
            dupliConsultationData: '[]',
            dupliOtherData: '[]',
            searchValue: '',
            other_service_type: '',
            newTimeTaken: '',
            newSelectedUnit: 'nos',
            newServiceAmount: '',
            newDoctorFee: '',
            newHospitalFee: '',
            newDoctorPercent: '',
            newHospitalPercent: '',
            selectedUnitCount: '1',
            minimum_amt: '',
            is_gst_enabled: JSON.parse(localGetItem("is_gst_enabled")),
            selectedMinTime: null,
            opServiceCodeCon: '',
            opServiceCodeOth: "",
            showCode: false,
            con_click_emergency: false,
            timeSlots: [],
            duplicateTimeSlot: '[]',
            otherTimeSlots: [],
            duplicateOtherTimeSlots: '[]',
            page: 0,
            rowsPerPage: 10,
        }
        this.TimeListArray = [];
        for (let i = 1; i <= 60; i++) {
            this.TimeListArray[i] = i < 10 ? `0${i}` : i;
        }
    }

    componentDidMount() {
        let showCode = JSON.parse(localGetItem('op_ip_service_code')) ? JSON.parse(localGetItem('op_ip_service_code')) : false
        this.setState({ showCode: showCode })
        this.getOpServiceConfigCategory()
        this.getOpServiceConfigDoctorData()
        this.getOpServiceConfigData()
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    getOpServiceConfigDoctorData = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({ doctorList: response.data.data })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getOpServiceConfigCategory = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_NEW_OP_SERVICE_CONFIG_CATEGORY_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            categoryData: response.data.data,
                            serviceCategory: response.data.data?.find((item) => item?.catagory_name === 'Consultation')
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getOpServiceConfigData = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_NEW_OP_SERVICE_CONFIG + '?service_type=')
                .then((response) => {
                    if (response.data.status === 'success') {
                        const ResponseData = response?.data?.emergency_time ? response?.data?.emergency_time[response?.data?.emergency_time?.length - 1] : null;
                        let emergencyTimeData = []; emergencyTimeData.push(ResponseData);

                        let uniqueEmergencyTimeData = [...emergencyTimeData];
                        if (emergencyTimeData.length > 1) {
                            const lastItem = emergencyTimeData[emergencyTimeData.length - 1];
                            const isDuplicate = emergencyTimeData.slice(0, -1).some(item =>
                                item.start_time === lastItem.start_time &&
                                item.end_time === lastItem.end_time
                            );

                            if (isDuplicate) {
                                uniqueEmergencyTimeData = emergencyTimeData.slice(0, -1);
                            }
                        }

                        const updatedTimeSlots = uniqueEmergencyTimeData.length > 0 ? uniqueEmergencyTimeData.map(item => ({
                            ...item,
                            eme_doc_total_amt: '',
                            eme_doc_fee: '',
                            eme_hos_fee: '',
                            eme_doc_percent: '',
                            eme_hos_percent: '',
                        })) : [
                            {
                                start_time: null,
                                end_time: null,
                                eme_doc_total_amt: '',
                                eme_doc_fee: '',
                                eme_hos_fee: '',
                                eme_doc_percent: '',
                                eme_hos_percent: '',
                            }
                        ]
                        const updatedOthersTimeSlots = uniqueEmergencyTimeData.length > 0 ? uniqueEmergencyTimeData.map(item => ({
                            ...item,
                            emergency_amt: '',
                        })) : [
                            {
                                start_time: null,
                                end_time: null,
                                emergency_amt: '',
                            }
                        ]
                        this.setState({
                            serviceConfigData: response.data.data,
                            duplicateServiceData: JSON.stringify(response.data.data),
                            timeSlots: updatedTimeSlots,
                            duplicateTimeSlot: JSON.stringify(updatedTimeSlots),
                            otherTimeSlots: updatedOthersTimeSlots,
                            duplicateOtherTimeSlots: JSON.stringify(updatedOthersTimeSlots),
                        }, () => { this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    deletedIds = (item) => {
        try {
            let removableIds = []
            let duplicateData = JSON.parse(this.state.dupliConsultationData)
            if (((((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'Consultation') || ((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'OP Procedures')))) {
                let duplicateHash = {}
                item?.forEach(element => {
                    duplicateHash[element.id] = element
                })
                duplicateData.forEach(element => {
                    if (!duplicateHash[element.id]) {
                        removableIds.push(element.id)
                    }
                })
            } else {
                let duplicateOtherData = JSON.parse(this.state.dupliOtherData)
                let otherDuplicateHash = {}
                item?.forEach(element => {
                    otherDuplicateHash[element.id] = element
                })
                duplicateOtherData.forEach(element => {
                    if (!otherDuplicateHash[element.id]) {
                        removableIds.push(element.id)
                    }
                })
            }
            return removableIds;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postOpServiceConfigData = () => {
        try {
            let list = (((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'Consultation') || ((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'OP Procedures')) ? this.state.serviceNameList : this.state.otherServiceList
            let removableIds = this.deletedIds(list)
            this.state.serviceNameList.forEach(element => {
                element.service_type = ((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'Consultation') ? 'Consultation' : this.state.service_type.trim()
            })
            this.state.otherServiceList.forEach(element => {
                element.service_type = this.state.other_service_type.trim()
            })
            let data = {
                "service_detail": ((((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'Consultation') || ((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'OP Procedures'))) ? this.state.serviceNameList : this.state.otherServiceList,
                "deleted_ids": removableIds
            }
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.FO_NEW_OP_SERVICE_CONFIG)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                        this.getOpServiceConfigData()
                        this.clearAllData()
                        this.clearOtherService()
                        this.setState({
                            serviceNameList: [],
                            otherServiceList: [],
                            service_type: '',
                            other_service_type: '',
                            selectedId: null,
                            serviceId: null,
                            isEdit: false,
                            serviceCategory: this.state.categoryData.find((item) => item.catagory_name === 'Consultation')
                        })
                        removableIds = []
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    deleteOpServiceConfigData = () => {
        try {
            let data = {}
            this.LoaderFunction(true)
            RestAPIService.delete(Serviceurls.FO_NEW_OP_SERVICE_CONFIG + `?id=${this.state.deletedIds}`, data)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                        this.getOpServiceConfigData()
                        this.setState({ deletePopup: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    UsersOption = (Access) => {
        if (Access?.is_edit_discount && Access?.is_edit_unit && Access?.is_edit_amount) {
            return "Unit & Amount & Discount"
        } else if (Access?.is_edit_discount && Access?.is_edit_unit) {
            return "Discount & Unit"
        } else if (Access?.is_edit_amount && Access?.is_edit_unit) {
            return "Amount & Unit"
        } else if (Access?.is_edit_amount && Access?.is_edit_discount) {
            return "Amount & Discount"
        } else if (Access?.is_edit_unit) {
            return "Unit"
        } else if (Access?.is_edit_discount) {
            return "Discount"
        } else if (Access?.is_edit_amount) {
            return "Amount"
        }
    }

    getCapitalize = (data) => {
        const capitalized = data.charAt(0).toUpperCase() + data.slice(1);
        return capitalized;
    }

    renderLeftGSTText = () => {
            const enableGstDiv = this.state.is_gst_enabled ? "block" : "none";
            return (
                <Typography display={enableGstDiv} margin={'0.5dvw'} fontSize={'0.7dvw'}
                    fontWeight={600} color={Colors.SecondaryText} textAlign={"right"}>*Inclusive Of GST</Typography>
            )
        }

    renderConsultionTbl = (item, index) => {
        item?.data?.forEach((element, serviceIndex) => element.sno = serviceIndex + 1)
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse in={this.state.selectedIndex === index} timeout={'auto'} unmountOnExit>
                {this.renderLeftGSTText()}
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>S.No</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Doctor Name</Typography>
                                    </TableCell>
                                    {/* {((item?.service_catagory === 'Consultation')) ?
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Doctor Name</Typography>
                                        </TableCell> :
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Service Name</Typography>
                                        </TableCell>
                                    } */}
                                    {((item?.service_catagory === 'OP Procedures')) ?
                                        <>
                                            {/* <TableCell sx={{ backgroundColor: Colors.Background }}>
                                                <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Unit</Typography>
                                            </TableCell> */}
                                            <TableCell sx={{ backgroundColor: Colors.Background }}>
                                                <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Service Amount</Typography>
                                            </TableCell>
                                        </>
                                        :
                                        <>
                                            <TableCell sx={{ backgroundColor: Colors.Background }}>
                                                <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>New Patient</Typography>
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: Colors.Background }}>
                                                <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Review Patient</Typography>
                                            </TableCell>
                                            <TableCell sx={{ backgroundColor: Colors.Background }}>
                                                <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Old Patient</Typography>
                                            </TableCell>
                                        </>
                                    }

                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Holiday Fee</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Emergency Fee</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item?.data?.length > 0 ? item?.data?.map((newItem, index) => (
                                    newItem?.service_charges?.map((data, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{newItem?.sno}</TableCell>
                                            <TableCell>
                                                <Box display={'flex'}>
                                                    {newItem?.service_name ? newItem?.service_name?.length > 12 ?
                                                        <Tooltip placement='top' title={newItem?.service_name}><div>{newItem?.service_name?.slice(0, 10) + '...'}</div></Tooltip> :
                                                        newItem?.service_name : '-'}
                                                    {newItem?.is_edit_amount || newItem?.is_edit_discount || newItem?.is_edit_unit ?
                                                        <Tooltip placement='top' title={this.UsersOption(newItem)}>
                                                            <Box component={'img'} src={ImagePaths.NewEditcon.default} height={'1vw'} width={'1vw'} position={'relative'} top={'0.2vw'} />
                                                        </Tooltip> : null}
                                                </Box>
                                            </TableCell>
                                            {((item?.service_catagory === 'OP Procedures')) ?
                                                <>
                                                    {/* <TableCell>{newItem?.unit ? newItem?.unit === 'hrs' ? 'Hrs' : newItem?.unit : '-'}</TableCell> */}
                                                    <TableCell>{data?.service_fee?.total_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled)? CommonValidation.CalculateGSTInclude(data?.service_fee?.total_amt, newItem?.gst_percentage) : data?.service_fee?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell>
                                                </>
                                                :
                                                <>
                                                    <TableCell>{data?.new_patient?.total_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled)? CommonValidation.CalculateGSTInclude(data?.new_patient?.total_amt, newItem?.gst_percentage) : data?.new_patient?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell>
                                                    <TableCell>{data?.review_patient?.total_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled)? CommonValidation.CalculateGSTInclude(data?.review_patient?.total_amt, newItem?.gst_percentage) : data?.review_patient?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell>
                                                    <TableCell>{data?.old_patient?.total_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled)? CommonValidation.CalculateGSTInclude(data?.old_patient?.total_amt, newItem?.gst_percentage) : data?.old_patient?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell></>
                                            }

                                            <TableCell>{data?.holiday_fee?.total_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled)? CommonValidation.CalculateGSTInclude(data?.holiday_fee?.total_amt, newItem?.gst_percentage) : data?.holiday_fee?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell>
                                            {/* <TableCell>{data?.emergency_fee?.total_amt ? ((newItem?.gst_percentage && this.state.is_gst_enabled)? CommonValidation.CalculateGSTInclude(data?.emergency_fee?.total_amt, newItem?.gst_percentage) : data?.emergency_fee?.total_amt) : 0}</TableCell> */}
                                            <TableCell>
                                                <Box component={'div'} id="eMed_Lab_techFont">
                                                    {data?.emergency_fee?.length > 0 ? (
                                                        <>
                                                            {data?.emergency_fee.map((list, index) => {
                                                                if (index < 2) {
                                                                    return (<Tooltip placement="top" title={data?.emergency_fee.map((list) => (
                                                                        <li style={{ fontSize: "0.8vw" }}>
                                                                            {list.start_time && list.end_time ? `${list.start_time} to ${list.end_time} - ${list?.total_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(list?.total_amt, newItem?.gst_percentage) : list?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}` : "0.00"}
                                                                        </li>))} arrow>
                                                                        <li id="eMed_Lab_techFont">
                                                                            {list.start_time && list.end_time
                                                                                ? `${list.start_time} to ${list.end_time} - ${list?.total_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(list?.total_amt, newItem?.gst_percentage) : list?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}`.length > 16
                                                                                    ? `${`${list.start_time} to ${list.end_time} - ${list?.total_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(list?.total_amt, newItem?.gst_percentage) : list?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}`.slice(0, 16)}...`
                                                                                    : `${list.start_time} to ${list.end_time} - ${list?.total_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(list?.total_amt, newItem?.gst_percentage) : list?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}`
                                                                                : 0}
                                                                        </li>
                                                                    </Tooltip>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                            {data?.emergency_fee?.length > 2 ? (
                                                                <Tooltip placement="top" title={data?.emergency_fee.map((list) => (
                                                                    <li style={{ fontSize: "0.8vw" }}>
                                                                        {list.start_time && list.end_time ? `${list.start_time} to ${list.end_time} - ${list?.total_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(list?.total_amt, newItem?.gst_percentage) : list?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}` : "0.00"}
                                                                    </li>
                                                                ))} arrow >
                                                                    <div id="eMed_Lab_techFont">{"....."}</div>
                                                                </Tooltip>
                                                            ) : ("")}
                                                        </>) : ("-")}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )) : null}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell></>
        )
    }

    renderTbl = (item, index) => {
        item?.data?.forEach((element, serviceIndex) => element.sno = serviceIndex + 1)
        return (
            <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse in={this.state.selectedIndex === index} timeout={'auto'} unmountOnExit>
                    {this.renderLeftGSTText()}
                    <Box margin={1} className='eMed_Pat_Bill_Tbl_Two'>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>S.No</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Service Name</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Unit</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Service Amount</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Holiday Fee</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Emergency Fee</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {item?.data?.length > 0 ? item?.data?.map((newItem) => (
                                    newItem?.service_charges?.map((data, newIndex) => (
                                        <TableRow key={newIndex}>
                                            <TableCell>{newItem?.sno}</TableCell>
                                            <TableCell>
                                                <Box display={'flex'}>
                                                    {newItem?.service_name ? newItem?.service_name?.length > 12 ?
                                                        <Tooltip placement='top' title={newItem?.service_name}><div>{newItem?.service_name?.slice(0, 12) + '...'}</div></Tooltip> :
                                                        newItem?.service_name : '-'}
                                                    {newItem?.is_edit_amount || newItem?.is_edit_discount || newItem?.is_edit_unit ?
                                                        <Tooltip placement='top' title={this.UsersOption(newItem)}>
                                                            <Box component={'img'} src={ImagePaths.NewEditcon.default} height={'1vw'} width={'1vw'} position={'relative'} top={'0.2vw'} />
                                                        </Tooltip> : null}
                                                </Box>
                                            </TableCell>
                                            <TableCell>{newItem?.unit ? newItem?.unit === 'hrs' ? `${newItem?.time_taken.slice(0, 5)} Hrs` : newItem?.unit : '-'}</TableCell>
                                            <TableCell>{data?.service_amount ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(data?.service_amount, newItem?.gst_percentage) : data?.service_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell>
                                            <TableCell>{data?.holiday_fee ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(data?.holiday_fee, newItem?.gst_percentage) : data?.holiday_fee)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell>
                                            {/* <TableCell>{data?.emergency_fee ? ((newItem?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(data?.emergency_fee, newItem?.gst_percentage) : data?.emergency_fee) : 0}</TableCell> */}
                                            <TableCell>
                                                <Box component={'div'} id="eMed_Lab_techFont">
                                                    {data?.emergency_fee?.length > 0 ? (
                                                        <>
                                                            {data?.emergency_fee.map((list, index) => {
                                                                if (index < 2) {
                                                                    return (<Tooltip placement="top" title={data?.emergency_fee.map((list) => (
                                                                        <li style={{ fontSize: "0.8vw" }}>
                                                                            {list.start_time && list.end_time ? `${list.start_time} to ${list.end_time} - ${list?.emergency_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(list?.emergency_amt, newItem?.gst_percentage) : list?.emergency_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}` : "0.00"}
                                                                        </li>))} arrow>
                                                                        <li id="eMed_Lab_techFont">
                                                                            {list.start_time && list.end_time ? `${list.start_time} to ${list.end_time} - ${list?.emergency_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(list?.emergency_amt, newItem?.gst_percentage) : list?.emergency_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}` : "0.00"}
                                                                        </li>
                                                                    </Tooltip>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                            {data?.emergency_fee?.length > 2 ? (
                                                                <Tooltip placement="top" title={data?.emergency_fee.map((list) => (
                                                                    <li style={{ fontSize: "0.8vw" }}>
                                                                        {list.start_time && list.end_time ? `${list.start_time} to ${list.end_time} - ${list?.emergency_amt ? AmountFormat((newItem?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(list?.emergency_amt, newItem?.gst_percentage) : list?.emergency_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}` : "0.00"}
                                                                    </li>
                                                                ))} arrow >
                                                                    <div id="eMed_Lab_techFont">{"....."}</div>
                                                                </Tooltip>
                                                            ) : ("")}
                                                        </>) : ("-")}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )) : null}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell></>
        )
    }

    onEditHandler = (item) => {
        try {
            if ((item?.service_catagory === 'Consultation') || (item?.service_catagory === 'OP Procedures')) {
                this.setState({
                    serviceNameList: item?.data,
                    service_type: item?.service_type,
                    serviceCategory: item?.service_catagory,
                    dupliConsultationData: JSON.stringify(item?.data)
                })
            } else {
                this.setState({
                    otherServiceList: item?.data,
                    other_service_type: item?.service_type,
                    serviceCategory: item?.service_catagory,
                    dupliOtherData: JSON.stringify(item?.data)
                })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    clearOnClickEdit = (item) => {
        try {
            this.clearAllData()
            this.clearOtherService()
            this.setState({
                service_amt: '',
                service_type: '',
                selectedUnit: 'nos',
                holiday_amt: '',
                emergency_amt: '',
                service_name: '',
                selectedTime: null,
                serviceNameList: [],
                otherServiceList: [],
                minimum_amt: '',
                gstPercentage : "",
                selectedMinTime:null
            }, () => this.onEditHandler(item))
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (item) => {
        try {
            let ids = []
            item?.data?.map((val) => ids.push(val?.id))
            if (!this.state.isEdit) {
                this.setState({
                    deletePopup: true,
                    deleteServiceType: item?.service_type,
                    deletedIds: ids
                })
            } else {
                this.errorMessage("Edit has been clicked.So,Please clear or Update service")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onSearchHandler = (e) => {
        try {
            this.setState({
                searchValue: e.target.value
            }, () => {
                let duplicateServiceData = JSON.parse(this.state.duplicateServiceData)
                if (this.state.searchValue !== '') {
                    duplicateServiceData = duplicateServiceData.filter((item) => {
                        return (
                            (item?.service_type === '' ? '' : item?.service_type?.toLowerCase()?.includes(this.state.searchValue?.toLowerCase())) ||
                            (item?.service_catagory === '' ? '' : item?.service_catagory?.toLowerCase()?.includes(this.state.searchValue?.toLowerCase()))
                        )
                    })
                    this.setState({ serviceConfigData: duplicateServiceData })
                } else {
                    this.setState({ serviceConfigData: duplicateServiceData })
                }
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderLeftContent = () => {
        this.state.serviceConfigData.forEach((element, index) => element.sno = index + 1)
        return (
            <Box component={'div'} height={'79dvh'} overflow={'auto'}>
                <Box component={'div'} height={'5vh'} alignItems={'center'} display={'flex'} justifyContent={'flex-end'} pr={'1vw'}>
                    <TextField
                        size='small'
                        variant='standard'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>)
                        }}
                        placeholder='Search...'
                        onChange={(e) => this.onSearchHandler(e)}
                        autoComplete='off'
                    />
                </Box>
                <Box component={'div'}>
                    <Box component={'div'} stickyHeader sx={{height:'65dvh',overflow:'scroll'}}>
                        <Table size='small' >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>S.No</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Category</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Service Type</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }} align='center'>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Action</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}></TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.serviceConfigData?.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage +
                                this.state.rowsPerPage).map((item, index) => (
                                    <TableBody style={{ height: "1.5vw" }}>
                                        {/* {this.state.serviceConfigData?.length > 0 ? this.state.serviceConfigData?.map((item, index) => ( */}
                                        <><TableRow key={index} style={{ height: "1.5vw" }}>
                                            <TableCell>
                                                <Typography>{item?.sno}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{item?.service_catagory}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{item?.service_type ? item?.service_type?.length > 18 ?
                                                    <Tooltip placement='top' title={item?.service_type}><div>{item?.service_type.slice(0, 15) + '...'}</div></Tooltip> : item?.service_type : '-'
                                                }</Typography>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Box>
                                                    <Button className='eMed_Config_Btn' sx={{ width: '1.7vw', height: '1.7vw' }}
                                                        onClick={() => { this.setState({ isEdit: true }, () => this.clearOnClickEdit(item)) }}
                                                        disabled={!CheckAccessFunc("front_office", "Settings", "OP Service Configuration", "Service Name & Type Config", "SubTab")?.editAccess}>
                                                        <Box
                                                            component={'img'}
                                                            src={CheckAccessFunc("front_office", "Settings", "OP Service Configuration", "Service Name & Type Config", "SubTab")?.editAccess ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default}
                                                            height={'1.5vw'} width={'1.5vw'}

                                                        />
                                                    </Button>
                                                    <Button
                                                        disabled={!CheckAccessFunc("front_office", "Settings", "OP Service Configuration", "Service Name & Type Config", "SubTab")?.editAccess}
                                                        className='eMed_Config_Btn'
                                                        sx={{ width: '1.7vw', height: '1.7vw' }}
                                                        onClick={() => this.onDeleteHandler(item)}
                                                    >
                                                        <Box component={'img'} src={CheckAccessFunc("front_office", "Settings", "OP Service Configuration", "Service Name & Type Config", "SubTab")?.editAccess ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} height={'1.5vw'} width={'1.5vw'} />
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size='small'
                                                    onClick={() => {
                                                        this.setState({ selectedIndex: this.state.selectedIndex === index ? null : index })
                                                    }}
                                                >
                                                    {this.state.selectedIndex === index ?
                                                        <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                            <TableRow>
                                                {((item?.service_catagory === 'Consultation') || (item?.service_catagory === 'OP Procedures')) ? this.renderConsultionTbl(item, index) : this.renderTbl(item, index)}
                                            </TableRow>
                                        </>
                                    </TableBody>
                                ))}
                        </Table>
                    </Box>
                    <TablePagination
                        component='div'
                        id="eMed_tbl_pagination"
                        rowsPerPageOptions={[10, 20, 30]}
                        count={this.state.serviceConfigData?.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
        )
    }

    calculateShareAmount = (value, names, index) => {
        try {
            let states = this.state
            const timeSlots = [...this.state.timeSlots];
            switch (names) {
                case 'patDoctorFee':
                    states.new_pat_hos_fee = +(states.new_pat_total_amt - +value).toFixed(2)
                    this.setState({ states })
                    break;
                case 'patHospitalFee':
                    states.new_pat_doc_fee = +(states.new_pat_total_amt - +value).toFixed(2)
                    this.setState({ states })
                    break;
                case 'revDoctorFee':
                    states.rev_pat_hos_fee = +(states.rev_pat_total_amt - +value).toFixed(2)
                    this.setState({ states })
                    break;
                case 'revHospitalFee':
                    states.rev_pat_doc_fee = +(states.rev_pat_total_amt - +value).toFixed(2)
                    this.setState({ states })
                    break;
                case 'oldDoctorFee':
                    states.old_pat_hos_fee = +(states.old_pat_total_amt - +value).toFixed(2)
                    this.setState({ states })
                    break;
                case 'oldHospitalFee':
                    states.old_pat_doc_fee = +(states.old_pat_total_amt - +value).toFixed(2)
                    this.setState({ states })
                    break;
                case 'holiDoctorFee':
                    states.holi_hos_fee = +(states.holi_total_amt - +value).toFixed(2)
                    this.setState({ states })
                    break;
                case 'holiHospitalFee':
                    states.holi_doc_fee = +(states.holi_total_amt - +value).toFixed(2)
                    this.setState({ states })
                    break;
                case 'emeDoctorFee':
                    timeSlots[index]["eme_hos_fee"] = +(timeSlots[index]["eme_doc_total_amt"] - +value).toFixed(2)
                    this.setState({ timeSlots })
                    break;
                case 'emeHospitalFee':
                    timeSlots[index]["eme_doc_fee"] = +(timeSlots[index]["eme_doc_total_amt"] - +value).toFixed(2)
                    this.setState({ timeSlots })
                    break;
                case 'newDoctorFee':
                    states.newHospitalFee = +(states.newServiceAmount - +value).toFixed(2)
                    this.setState({ states })
                    break;
                case 'newHospitalFee':
                    states.newDoctorFee = +(states.newServiceAmount - +value).toFixed(2)
                    this.setState({ states })
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateSharePercentage = (value, names, index) => {
        try {
            let states = this.state
            const timeSlots = [...this.state.timeSlots];
            switch (names) {
                case 'patDoctorFee':
                    states.new_pat_hos_percent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'patHospitalFee':
                    states.new_pat_doc_percent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'revDoctorFee':
                    states.rev_pat_hos_percent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'revHospitalFee':
                    states.rev_pat_doc_percent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'oldDoctorFee':
                    states.old_pat_hos_percent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'oldHospitalFee':
                    states.old_pat_doc_percent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'holiDoctorFee':
                    states.holi_hos_percent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'holiHospitalFee':
                    states.holi_doc_percent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'emeDoctorFee':
                    timeSlots[index]["eme_hos_percent"] = +(100 - (+value)).toFixed(2)
                    this.setState({ timeSlots })
                    break;
                case 'emeHospitalFee':
                    timeSlots[index]["eme_doc_percent"] = +(100 - (+value)).toFixed(2)
                    this.setState({ timeSlots })
                    break;
                case 'newDoctorFee':
                    states.newHospitalPercent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                case 'newHospitalFee':
                    states.newDoctorPercent = +(100 - (+value)).toFixed(2)
                    this.setState({ states })
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeHandler = (e, names, index) => {
        try {
            const timeSlots = [...this.state.timeSlots];
            let states = this.state
            let value = e.target.value
            switch (names) {
                case 'service_type':
                    if (CommonValidation.serviceType(value) || value === '') {
                        if (((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'Consultation')) {
                            this.setState({ service_type: 'Consultation' })
                        } else {
                            this.setState({ service_type: value })
                        }
                    }
                    break;
                case 'service_amt':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        this.setState({ service_amt: +value })
                    }
                    break;
                case 'minimum_amt':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        this.setState({ minimum_amt: +value })
                    }
                    break;
                case 'service_name':
                    if (CommonValidation.serviceType(value) || value === '') {
                        this.setState({ service_name: value })
                    }
                    break;
                case 'holidayAmt':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        this.setState({ holiday_amt: +value })
                    }
                    break;
                case 'emergencyAmt':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        const otherTimeSlots = [...this.state.otherTimeSlots]
                        otherTimeSlots[index]["emergency_amt"] = +value
                        this.setState({ otherTimeSlots })
                    }
                    break;
                case 'service_name':
                    if (CommonValidation.serviceType(value) || value === '') {
                        this.setState({ service_name: value })
                    }
                    break;

                case 'patTotalAmt':
                    if (CommonValidation.NumberWithDot(value) || value == '') {
                        states.new_pat_total_amt = +value
                        states.new_pat_doc_fee = ''
                        states.new_pat_doc_percent = ''
                        states.new_pat_hos_fee = ''
                        states.new_pat_hos_percent = ''
                        this.setState({ states })
                    }
                    break;
                case 'patDoctorFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= states.new_pat_total_amt || value === '') {
                            states.new_pat_doc_fee = +value
                            this.setState({ states }, () => this.calculateShareAmount(value, names))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            states.new_pat_doc_percent = value
                            this.setState({ states }, () => this.calculateSharePercentage(value, names))
                        }
                    }
                    break;
                case 'patHospitalFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= states.new_pat_total_amt || value == '') {
                            states.new_pat_hos_fee = +value
                            this.setState({ states }, () => this.calculateShareAmount(value, names))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            states.new_pat_hos_percent = value
                            this.setState({ states }, () => this.calculateSharePercentage(value, names))
                        }
                    }
                    break;

                case 'revTotalAmt':
                    if (CommonValidation.NumberWithDot(value) || value == '') {
                        states.rev_pat_total_amt = +value
                        states.rev_pat_doc_fee = ''
                        states.rev_pat_doc_percent = ''
                        states.rev_pat_hos_fee = ''
                        states.rev_pat_hos_percent = ''
                        this.setState({ states })
                    }
                    break;
                case 'revDoctorFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= states.rev_pat_total_amt || value == '') {
                            states.rev_pat_doc_fee = +value
                            this.setState({ states }, () => this.calculateShareAmount(value, names))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            states.rev_pat_doc_percent = value
                            this.setState({ states }, () => this.calculateSharePercentage(value, names))
                        }
                    }

                    break;
                case 'revHospitalFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= states.rev_pat_total_amt || value == '') {
                            states.rev_pat_hos_fee = +value
                            this.setState({ states }, () => this.calculateShareAmount(value, names))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            states.rev_pat_hos_percent = value
                            this.setState({ states }, () => this.calculateSharePercentage(value, names))
                        }
                    }
                    break;

                case 'oldTotalAmt':
                    if (CommonValidation.NumberWithDot(value) || value == '') {
                        states.old_pat_total_amt = +value
                        states.old_pat_doc_fee = ''
                        states.old_pat_doc_percent = ''
                        states.old_pat_hos_fee = ''
                        states.old_pat_hos_percent = ''
                        this.setState({ states })
                    }
                    break;
                case 'oldDoctorFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= states.old_pat_total_amt || value == '') {
                            states.old_pat_doc_fee = +value
                            this.setState({ states }, () => this.calculateShareAmount(value, names))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            states.old_pat_doc_percent = value
                            this.setState({ states }, () => this.calculateSharePercentage(value, names))
                        }
                    }
                    break;
                case 'oldHospitalFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= states.old_pat_total_amt || value == '') {
                            states.old_pat_hos_fee = +value
                            this.setState({ states }, () => this.calculateShareAmount(value, names))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            states.old_pat_hos_percent = value
                            this.setState({ states }, () => this.calculateSharePercentage(value, names))
                        }
                    }
                    break;

                case 'holiTotalAmt':
                    if (CommonValidation.NumberWithDot(value) || value == '') {
                        states.holi_total_amt = +value
                        states.holi_doc_fee = ''
                        states.holi_doc_percent = ''
                        states.holi_hos_fee = ''
                        states.holi_hos_percent = ''
                        this.setState({ states })
                    }
                    break;
                case 'holiDoctorFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= states.holi_total_amt || value == '') {
                            states.holi_doc_fee = +value
                            this.setState({ states }, () => this.calculateShareAmount(value, names))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            states.holi_doc_percent = value
                            this.setState({ states }, () => this.calculateSharePercentage(value, names))
                        }
                    }
                    break;
                case 'holiHospitalFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= states.holi_total_amt || value == '') {
                            states.holi_hos_fee = +value
                            this.setState({ states }, () => this.calculateShareAmount(value, names))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            states.holi_hos_percent = value
                            this.setState({ states }, () => this.calculateSharePercentage(value, names))
                        }
                    }
                    break;

                case 'emeTotalAmt':
                    if (CommonValidation.NumberWithDot(value) || value == '') {
                        timeSlots[index]["eme_doc_total_amt"] = +value;
                        timeSlots[index]["eme_doc_fee"] = "";
                        timeSlots[index]["eme_doc_percent"] = "";
                        timeSlots[index]["eme_hos_fee"] = "";
                        timeSlots[index]["eme_hos_percent"] = "";
                        this.setState({ timeSlots });
                        // states.eme_doc_total_amt = +value
                        // states.eme_doc_fee = ''
                        // states.eme_doc_percent = ''
                        // states.eme_hos_fee = ''
                        // states.eme_hos_percent = ''
                        // this.setState({ states })
                    }
                    break;
                case 'emeDoctorFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= timeSlots[index]["eme_doc_total_amt"] || value == '') {
                            timeSlots[index]["eme_doc_fee"] = +value
                            this.setState({ timeSlots }, () => this.calculateShareAmount(value, names, index))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            timeSlots[index]["eme_doc_percent"] = value
                            this.setState({ timeSlots }, () => this.calculateSharePercentage(value, names, index))
                        }
                    }
                    break;
                case 'emeHospitalFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= timeSlots[index]["eme_doc_total_amt"] || value == '') {
                            timeSlots[index]["eme_hos_fee"] = +value
                            this.setState({ timeSlots }, () => this.calculateShareAmount(value, names, index))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            timeSlots[index]["eme_hos_percent"] = value
                            this.setState({ timeSlots }, () => this.calculateSharePercentage(value, names, index))
                        }
                    }
                    break;
                case 'other_service_type':
                    if (CommonValidation.serviceType(value) || value === '') {
                        this.setState({ other_service_type: value })
                    }
                    break;
                case 'newServiceAmount':
                    if (CommonValidation.NumberWithDot(value) || value === '') {
                        this.setState({
                            newServiceAmount: value,
                            newDoctorFee: '',
                            newHospitalFee: '',
                            newDoctorPercent: '',
                            newHospitalPercent: ''
                        })
                    }
                    break;
                case 'newDoctorFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= +states.newServiceAmount || value === '') {
                            states.newDoctorFee = +value
                            this.setState({ states }, () => this.calculateShareAmount(value, names))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            states.newDoctorPercent = value
                            this.setState({ states }, () => this.calculateSharePercentage(value, names))
                        }
                    }
                    break;
                case 'newHospitalFee':
                    if (states.selectedSymbol === 'rupee') {
                        if (CommonValidation.NumberWithDot(value) && value <= +states.newServiceAmount || value === '') {
                            states.newHospitalFee = +value
                            this.setState({ states }, () => this.calculateShareAmount(value, names))
                        }
                    } else {
                        if (CommonValidation.DecimalNumber(value) && value <= 100 || value === '') {
                            states.newHospitalPercent = value
                            this.setState({ states }, () => this.calculateSharePercentage(value, names))
                        }
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    inputTextBox = (names, values, label, width, mr, dropWithTextBox, ml, disable, index) => {
        try {
            return (
                <TextField
                    size='small'
                    disabled={disable}
                    sx={{ width: width, mr: mr, ml: ml }}
                    label={label}
                    name={names}
                    value={values}
                    autoComplete='off'
                    InputProps={dropWithTextBox ? {
                        style: { paddingRight: '0.5vw' },
                        endAdornment:
                            this.state.selectedSymbol === 'rupee' ?
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{CurrencySymbol}</Typography> :
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>%</Typography>
                        // <Select
                        //     variant='standard'
                        //     disabled
                        //     sx={{ width: '5vw', height: '2.5vw' }}
                        //     value={this.state.selectedSymbol}
                        //     onChange={(e) => { this.setState({ selectedSymbol: e.target.value }) }}
                        // >
                        //     <MenuItem value={'rupee'}>₹</MenuItem>
                        //     <MenuItem value={'percentage'}>%</MenuItem>
                        // </Select>
                    } : null}
                    onChange={(e) => this.onChangeHandler(e, names, index)}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    clearAllData = () => {
        try {
            this.setState({
                service_name: '',
                doctor_name: null,
                new_pat_doc_fee: '', new_pat_doc_percent: '',
                new_pat_hos_fee: '', new_pat_hos_percent: '',
                rev_pat_doc_fee: '', rev_pat_doc_percent: '',
                rev_pat_hos_fee: '', rev_pat_hos_percent: '',
                old_pat_doc_fee: '', old_pat_doc_percent: '',
                old_pat_hos_fee: '', old_pat_hos_percent: '',
                holi_doc_fee: '', holi_doc_percent: '',
                holi_hos_fee: '', holi_hos_percent: '',
                eme_doc_fee: '', eme_doc_percent: '',
                eme_hos_fee: '', eme_hos_percent: '',
                new_pat_total_amt: '', rev_pat_total_amt: '',
                old_pat_total_amt: '', eme_doc_total_amt: '',
                holi_total_amt: '',
                isEditAmount: false,
                isEditDiscount: false,
                isEditUnit: false,
                serviceConfigData: JSON.parse(this.state.duplicateServiceData),
                editSelectedIndex: null,
                otherServiceEdit: false,
                editIndex: null,
                selectedSymbol: 'rupee',
                conEdit: false,
                newDoctorFee: '',
                newHospitalFee: '',
                newDoctorPercent: '',
                newHospitalPercent: '',
                newServiceAmount: '',
                newSelectedUnit: 'nos',
                serviceId: null,
                opServiceCodeCon: '',
                gstPercentage: '',
                timeSlots: JSON.parse(this.state.duplicateTimeSlot),
                otherTimeSlots: JSON.parse(this.state.duplicateOtherTimeSlots)
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkboxHandler = (e, key) => {
        try {
            let states = this.state
            let value = e.target.checked
            states[key] = value
            this.setState({ states })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeType = (e) => {
        try {

            this.setState({
                selectedSymbol: e.target.value,
                new_pat_doc_fee: '', new_pat_doc_percent: '',
                new_pat_hos_fee: '', new_pat_hos_percent: '',
                rev_pat_doc_fee: '', rev_pat_doc_percent: '',
                rev_pat_hos_fee: '', rev_pat_hos_percent: '',
                old_pat_doc_fee: '', old_pat_doc_percent: '',
                old_pat_hos_fee: '', old_pat_hos_percent: '',
                holi_doc_fee: '', holi_doc_percent: '',
                holi_hos_fee: '', holi_hos_percent: '',
                eme_doc_fee: '', eme_doc_percent: '',
                eme_hos_fee: '', eme_hos_percent: '',
                newDoctorFee: '', newHospitalFee: '',
                newDoctorPercent: '', newHospitalPercent: '',
                emeTotalAmt : '', emeDoctorFee : "",
                emeHospitalFee: "", emergencyAmt: "",
                timeSlots: JSON.parse(this.state.duplicateTimeSlot),
                otherTimeSlots: JSON.parse(this.state.duplicateOtherTimeSlots)
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkDuplicate = () => {
        try {
            let states = this.state
            let { serviceNameList } = this.state
            let message = null;
            if ((((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'OP Procedures'))) {
                if (states.service_type !== '' && (states.doctor_name !== null || states.service_name !== '') && states.newServiceAmount !== '') {
                    // if (states.selectedUnit === 'hrs' && states.newTimeTaken !== '') {
                    //     message = "Select Time"
                    // } else {
                    serviceNameList.forEach((element, index) => {
                        if (index !== this.state.editIndex) {
                            // if ((CommonValidation.removeSpace(element.service_name) === CommonValidation.removeSpace(this.state.service_name))) {
                            //     message = "Service Already Exist"
                            // }
                            if ((CommonValidation.removeSpace(element.doctor_name) === CommonValidation.removeSpace(this.state.doctor_name?.doctor_name)) || (CommonValidation.removeSpace(element.doctor_name) === CommonValidation.removeSpace(this.state.doctor_name))) {
                                message = "Service Name Already Exist"
                            } else if (this.state.opServiceCodeCon !== '' && element.op_service_code?.toLocaleLowerCase() === CommonValidation.removeSpace(this.state.opServiceCodeCon)?.toLocaleLowerCase()) {
                                message = 'Service Code Already Exist'
                            }
                        }
                    })
                    // }
                } else {
                    message = 'Enter All Mandatory Fields'
                }
            } else {
                if ((states.doctor_name !== null || states.service_name !== '') &&
                    states.new_pat_total_amt !== '' && states.rev_pat_total_amt !== '' && states.old_pat_total_amt !== '') {
                    serviceNameList.forEach((element, index) => {
                        if (index !== this.state.editIndex) {
                            if ((CommonValidation.removeSpace(element.doctor_name) === CommonValidation.removeSpace(this.state.doctor_name?.doctor_name)) || (CommonValidation.removeSpace(element.doctor_name) === CommonValidation.removeSpace(this.state.doctor_name))) {
                                message = "Service Name Already Exist"
                            } else if (this.state.opServiceCodeCon !== '' && element.op_service_code?.toLocaleLowerCase() === CommonValidation.removeSpace(this.state.opServiceCodeCon)?.toLocaleLowerCase()) {
                                message = 'Service Code Already Exist'
                            }
                        }
                    })
                } else {
                    message = "Enter All Mandatory Fields"
                }
            }
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    addServiceListData = () => {
        try {
            let duplicate = this.checkDuplicate()
            if (duplicate === null) {
                let states = this.state
                let { serviceNameList, gstPercentage } = this.state
                let serviceCatageoryId = states.categoryData?.find((item) => item?.catagory_name === this.state.serviceCategory)
                let categoryId = this.state.serviceCategory?.id ? this.state.serviceCategory?.id :
                    this.state.editServiceCategoryId ? this.state.editServiceCategoryId :
                        serviceCatageoryId?.id ? serviceCatageoryId?.id : null
                const emergencyFees = states.timeSlots.map((slot) => ({
                    "total_amt": slot.eme_doc_total_amt,
                    "doctor_fee": states.selectedSymbol === 'rupee'
                        ? slot.eme_doc_fee
                        : CommonValidation.calculatePercentage(slot.eme_doc_percent, slot.eme_doc_total_amt),
                    "hospital_fee": states.selectedSymbol === 'rupee'
                        ? slot.eme_hos_fee
                        : CommonValidation.calculatePercentage(slot.eme_hos_percent, slot.eme_doc_total_amt),
                    "doctor_percentage": states.selectedSymbol === 'rupee'
                        ? CommonValidation.amountPercentage(slot.eme_doc_fee, slot.eme_doc_total_amt)
                        : +slot.eme_doc_percent,
                    "hospital_percentage": states.selectedSymbol === 'rupee'
                        ? CommonValidation.amountPercentage(slot.eme_hos_fee, slot.eme_doc_total_amt)
                        : +slot.eme_hos_percent,
                    "amount_type": states.selectedSymbol,
                    "start_time": slot.start_time,
                    "end_time": slot.end_time
                }));
                let service_list = {
                    "op_service_code": this.state.opServiceCodeCon ? CommonValidation.removeSpace(this.state.opServiceCodeCon) : '',
                    "service_catagory_id": categoryId,
                    "service_name": this.state.doctor_name?.doctor_name ? CommonValidation.removeSpace(this.state.doctor_name?.doctor_name) : this.state.doctor_name ? CommonValidation.removeSpace(this.state.doctor_name) : this.state.service_name ? CommonValidation.removeSpace(this.state.service_name) : '',
                    // "service_type": this.state.service_type ? CommonValidation.removeSpace(this.state.service_type) : '',
                    "doctor_id": this.state.doctor_name?.doctor_id ? this.state.doctor_name?.doctor_id : this.state.doctor_id,
                    "doctor_name": this.state.doctor_name?.doctor_name ? CommonValidation.removeSpace(this.state.doctor_name?.doctor_name) : this.state.doctor_name ? CommonValidation.removeSpace(this.state.doctor_name) : CommonValidation.removeSpace(this.state.service_name),
                    "is_edit_unit": this.state.isEditUnit,
                    "is_edit_amount": this.state.isEditAmount,
                    "is_edit_discount": this.state.isEditDiscount,
                    "time_taken": null,
                    // (((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'OP Procedures')) ? this.state.newSelectedUnit === 'hrs' ? this.state.newTimeTaken : null : null,
                    "unit": "1",
                    // (((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'OP Procedures')) ? this.state.newSelectedUnit === 'hrs' ? 'hrs' : '1' : "1",
                    'gst_percentage': this.state.is_gst_enabled ? (+gstPercentage || 0) : 0,
                    "service_charges": [{
                        "new_patient": {
                            "total_amt": +states.new_pat_total_amt,
                            "doctor_fee": states.selectedSymbol === 'rupee' ? states.new_pat_doc_fee : CommonValidation.calculatePercentage(states.new_pat_doc_percent, states.new_pat_total_amt),
                            "hospital_fee": states.selectedSymbol === 'rupee' ? states.new_pat_hos_fee : CommonValidation.calculatePercentage(states.new_pat_hos_percent, states.new_pat_total_amt),
                            "doctor_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.new_pat_doc_fee, states.new_pat_total_amt) : +states.new_pat_doc_percent,
                            "hospital_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.new_pat_hos_fee, states.new_pat_total_amt) : +states.new_pat_hos_percent,
                            "amount_type": states.selectedSymbol,
                        },
                        "old_patient": {
                            "total_amt": +states.old_pat_total_amt,
                            "doctor_fee": states.selectedSymbol === 'rupee' ? states.old_pat_doc_fee : CommonValidation.calculatePercentage(states.old_pat_doc_percent, states.old_pat_total_amt),
                            "hospital_fee": states.selectedSymbol === 'rupee' ? states.old_pat_hos_fee : CommonValidation.calculatePercentage(states.old_pat_hos_percent, states.old_pat_total_amt),
                            "doctor_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.old_pat_doc_fee, states.old_pat_total_amt) : +states.old_pat_doc_percent,
                            "hospital_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.old_pat_hos_fee, states.old_pat_total_amt) : +states.old_pat_hos_percent,
                            "amount_type": states.selectedSymbol,
                        },
                        "review_patient": {
                            "total_amt": +states.rev_pat_total_amt,
                            "doctor_fee": states.selectedSymbol === 'rupee' ? states.rev_pat_doc_fee : CommonValidation.calculatePercentage(states.rev_pat_doc_percent, states.rev_pat_total_amt),
                            "hospital_fee": states.selectedSymbol === 'rupee' ? states.rev_pat_hos_fee : CommonValidation.calculatePercentage(states.rev_pat_hos_percent, states.rev_pat_total_amt),
                            "doctor_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.rev_pat_doc_fee, states.rev_pat_total_amt) : +states.rev_pat_doc_percent,
                            "hospital_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.rev_pat_hos_fee, states.rev_pat_total_amt) : +states.rev_pat_hos_percent,
                            "amount_type": states.selectedSymbol,   
                        },
                        "holiday_fee": {
                            "total_amt": +states.holi_total_amt,
                            "doctor_fee": states.selectedSymbol === 'rupee' ? states.holi_doc_fee : CommonValidation.calculatePercentage(states.holi_doc_percent, states.holi_total_amt),
                            "hospital_fee": states.selectedSymbol === 'rupee' ? states.holi_hos_fee : CommonValidation.calculatePercentage(states.holi_hos_percent, states.holi_total_amt),
                            "doctor_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.holi_doc_fee, states.holi_total_amt) : +states.holi_doc_percent,
                            "hospital_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.holi_hos_fee, states.holi_total_amt) : +states.holi_hos_percent,
                            "amount_type": states.selectedSymbol,
                        },
                        // "emergency_fee": {
                        //     "total_amt": states.eme_doc_total_amt,
                        //     "doctor_fee": states.selectedSymbol === 'rupee' ? states.eme_doc_fee : CommonValidation.calculatePercentage(states.eme_doc_percent, states.eme_doc_total_amt),
                        //     "hospital_fee": states.selectedSymbol === 'rupee' ? states.eme_hos_fee : CommonValidation.calculatePercentage(states.eme_hos_percent, states.eme_doc_total_amt),
                        //     "doctor_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentage(states.eme_doc_fee, states.eme_doc_total_amt) : states.eme_doc_percent,
                        //     "hospital_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentage(states.eme_hos_fee, states.eme_doc_total_amt) : states.eme_hos_percent,
                        //     "amount_type": states.selectedSymbol
                        // },
                        "emergency_fee": emergencyFees,
                        "service_fee": {
                            "total_amt": +states.newServiceAmount,
                            'doctor_fee': states.selectedSymbol === 'rupee' ? +states.newDoctorFee : CommonValidation.calculatePercentage(states.newDoctorPercent, states.newServiceAmount),
                            'hospital_fee': states.selectedSymbol === 'rupee' ? +states.newHospitalFee : CommonValidation.calculatePercentage(states.newHospitalPercent, states.newServiceAmount),
                            'doctor_percentage': states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.newDoctorFee, states.newServiceAmount) : +states.newDoctorPercent,
                            'hospital_percentage': states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.newHospitalFee, states.newServiceAmount) : +states.newHospitalPercent,
                            'amount_type': states.selectedSymbol,
                        }
                    }],
                    "additional_charges": [{
                        "holiday_fee": {
                            "total_amt": +states.holi_total_amt,
                            "doctor_fee": states.selectedSymbol === 'rupee' ? states.holi_doc_fee : CommonValidation.calculatePercentage(states.holi_doc_percent, states.holi_total_amt),
                            "hospital_fee": states.selectedSymbol === 'rupee' ? states.holi_hos_fee : CommonValidation.calculatePercentage(states.holi_hos_percent, states.holi_total_amt),
                            "doctor_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.holi_doc_fee, states.holi_total_amt) : +states.holi_doc_percent,
                            "hospital_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentageWithoutRoundoff(states.holi_hos_fee, states.holi_total_amt) : +states.holi_hos_percent,
                            "amount_type": states.selectedSymbol,
                        },
                        "emergency_fee": emergencyFees,
                        // "emergency_fee": {
                        //     "total_amt": states.eme_doc_total_amt,
                        //     "doctor_fee": states.selectedSymbol === 'rupee' ? states.eme_doc_fee : CommonValidation.calculatePercentage(states.eme_doc_percent, states.eme_doc_total_amt),
                        //     "hospital_fee": states.selectedSymbol === 'rupee' ? states.eme_hos_fee : CommonValidation.calculatePercentage(states.eme_hos_percent, states.eme_doc_total_amt),
                        //     "doctor_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentage(states.eme_doc_fee, states.eme_doc_total_amt) : states.eme_doc_percent,
                        //     "hospital_percentage": states.selectedSymbol === 'rupee' ? CommonValidation.amountPercentage(states.eme_hos_fee, states.eme_doc_total_amt) : states.eme_hos_percent,
                        //     "amount_type": states.selectedSymbol
                        // }
                    }],
                    "amount_type": states.selectedSymbol,
                    'id': states.serviceId
                }

                // if (this.state.isEdit) { service_list['id'] = states.serviceId }

                let data = this.updateOrInsertList(serviceNameList, service_list, this.state.editIndex)
                this.setState({ serviceNameList: data }, () => { this.clearAllData() })
            } else {
                this.errorMessage(duplicate)
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    serviceListEdit = (item, data, index) => {
        try {
            const timeSlots = data?.emergency_fee.length > 0 ? data?.emergency_fee?.map((fee) => ({
                eme_doc_fee: fee.amount_type === 'rupee' ? fee.doctor_fee : fee.doctor_percentage,
                eme_doc_percent: fee.doctor_percentage,
                eme_hos_fee: fee.amount_type === 'rupee' ? fee.hospital_fee : fee.hospital_percentage,
                eme_hos_percent: fee.hospital_percentage,
                eme_doc_total_amt: fee.total_amt,
                start_time: fee.start_time,
                end_time: fee.end_time,
            })) : JSON.parse(this.state.duplicateTimeSlot);
            this.setState({
                editIndex: index,
                new_pat_doc_fee: data?.new_patient?.amount_type === 'rupee' ? data?.new_patient?.doctor_fee : data?.new_patient?.doctor_percentage,
                new_pat_doc_percent: data?.new_patient?.doctor_percentage,
                new_pat_hos_fee: data?.new_patient?.amount_type === 'rupee' ? data?.new_patient?.hospital_fee : data?.new_patient?.hospital_percentage,
                new_pat_hos_percent: data?.new_patient?.hospital_percentage,
                rev_pat_doc_fee: data?.review_patient?.amount_type === 'rupee' ? data?.review_patient?.doctor_fee : data?.review_patient?.doctor_percentage,
                rev_pat_doc_percent: data?.review_patient?.doctor_percentage,
                rev_pat_hos_fee: data?.review_patient?.amount_type === 'rupee' ? data?.review_patient?.hospital_fee : data?.review_patient?.hospital_percentage,
                rev_pat_hos_percent: data?.review_patient?.hospital_percentage,
                old_pat_doc_fee: data?.old_patient?.amount_type === 'rupee' ? data?.old_patient?.doctor_fee : data?.old_patient?.doctor_percentage,
                old_pat_doc_percent: data?.old_patient?.doctor_percentage,
                old_pat_hos_fee: data?.old_patient?.amount_type === 'rupee' ? data?.old_patient?.hospital_fee : data?.old_patient?.hospital_percentage,
                old_pat_hos_percent: data?.old_patient?.hospital_percentage,
                holi_doc_fee: data?.holiday_fee?.amount_type === 'rupee' ? data?.holiday_fee?.doctor_fee : data?.holiday_fee?.doctor_percentage,
                holi_doc_percent: data?.holiday_fee?.doctor_percentage,
                holi_hos_fee: data?.holiday_fee?.amount_type === 'rupee' ? data?.holiday_fee?.hospital_fee : data?.holiday_fee?.hospital_percentage,
                holi_hos_percent: data?.holiday_fee?.hospital_percentage,
                timeSlots: timeSlots,
                // eme_doc_fee: data?.emergency_fee?.amount_type === 'rupee' ? data?.emergency_fee?.doctor_fee : data?.emergency_fee?.doctor_percentage,
                // eme_doc_percent: data?.emergency_fee?.doctor_percentage,
                // eme_hos_fee: data?.emergency_fee?.amount_type === 'rupee' ? data?.emergency_fee?.hospital_fee : data?.emergency_fee?.hospital_percentage,
                // eme_hos_percent: data?.emergency_fee?.hospital_percentage,
                new_pat_total_amt: data?.new_patient?.total_amt,
                rev_pat_total_amt: data?.review_patient?.total_amt,
                old_pat_total_amt: data?.old_patient?.total_amt,
                holi_total_amt: data?.holiday_fee?.total_amt,
                // eme_doc_total_amt: data?.emergency_fee?.total_amt,
                doctor_name: item?.doctor_name,
                doctor_id: item?.doctor_id,
                selectedSymbol: data?.new_patient?.amount_type,
                editServiceCategoryId: item?.service_catagory_id,
                isEditAmount: item?.is_edit_amount,
                isEditDiscount: item?.is_edit_discount,
                isEditUnit: item?.is_edit_unit,
                serviceId: item?.id ? item?.id : null,
                conEdit: true,
                service_name: item?.service_name,
                newDoctorFee: data?.service_fee?.doctor_fee,
                newHospitalFee: data?.service_fee?.hospital_fee,
                newServiceAmount: data?.service_fee?.total_amt,
                newDoctorPercent: data?.service_fee?.doctor_percentage,
                newHospitalPercent: data?.service_fee?.hospital_percentage,
                newSelectedUnit: item?.unit === 'hrs' ? 'hrs' : 'nos',
                newTimeTaken: item?.time_taken,
                gstPercentage: this.state.is_gst_enabled ? item?.gst_percentage : 0,
                opServiceCodeCon: item?.op_service_code ? item?.op_service_code : ''
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderGstTextBox = () => {
        const enableGstDiv = this.state.is_gst_enabled ? "block" : "none";
        return (
            <Box component={'div'} padding={'0.6dvw 0.5dvw'} borderBottom={'1px solid lightgray'} display={enableGstDiv}>
                <Typography marginBottom={'0.5dvw'} fontSize={'0.9dvw'} fontWeight={600} color={Colors.SecondaryText}>GST</Typography>
                <Autocomplete
                    disableClearable
                    size='small'
                    sx={{ width: '16dvw' }}
                    options={gstPercentage?.length > 0 ? gstPercentage : []}
                    getOptionLabel={(options) => typeof (options) === "string" ? options : options.label}
                    value={this.state.gstPercentage ? this.state.gstPercentage?.toString() : ""}
                    onChange={(e, newPercent) => {
                        if (typeof newPercent === 'object') {
                            this.setState({
                                gstPercentage: newPercent.value ? +newPercent.value : ''
                            });
                        } else {
                            this.setState({
                                gstPercentage: newPercent ? +newPercent : ''
                            });
                        }
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={'GST (%)'}
                            autoComplete="off"
                            sx={{ backgroundColor: 'white' }}
                            type='search' />}
                    inputProps={{ 'emed_tid': 'Opsrv_config_gst' }}
                />
            </Box>
        )
    }

    renderGStTxt = () => {
        const enableGstDiv = this.state.is_gst_enabled ? "block" : "none";
        return (
            <Typography display={enableGstDiv} margin={'0.5dvw'} fontSize={'0.9dvw'}
                fontWeight={600} color={Colors.SecondaryText} textAlign={"right"}>*Inclusive Of GST</Typography>
        )
    }

    handleTimeSlotChange = (index, field, value) => {
        const timeSlots = [...this.state.timeSlots];
        timeSlots[index][field] = value ? value.format('hh:mm A') : null;
        this.setState({ timeSlots });
    };

    handleOtherTimeSlotChange = (index, field, value) => {
        const otherTimeSlots = [...this.state.otherTimeSlots];
        otherTimeSlots[index][field] = value ? value.format('hh:mm A') : null;
        this.setState({ otherTimeSlots });
    };

    renderConsultionScreen = () => {
        let states = this.state
        let { selectedSymbol, is_gst_enabled } = this.state

        let newPatient = states.new_pat_total_amt === '' ? true : false
        let oldPatient = states.old_pat_total_amt === '' ? true : false
        let revPatient = states.rev_pat_total_amt === '' ? true : false
        let holiday = states.holi_total_amt === '' ? true : false
        let emergency = states.eme_doc_total_amt === '' ? true : false
        let newDoctor = states.newServiceAmount === '' ? true : false

        let keyOne = selectedSymbol === 'rupee' ? states.new_pat_doc_fee : states.new_pat_doc_percent
        let keyTwo = selectedSymbol === 'rupee' ? states.new_pat_hos_fee : states.new_pat_hos_percent
        let keyThree = selectedSymbol === 'rupee' ? states.rev_pat_doc_fee : states.rev_pat_doc_percent
        let keyFour = selectedSymbol === 'rupee' ? states.rev_pat_hos_fee : states.rev_pat_hos_percent
        let keyFive = selectedSymbol === 'rupee' ? states.old_pat_doc_fee : states.old_pat_doc_percent
        let keySix = selectedSymbol === 'rupee' ? states.old_pat_hos_fee : states.old_pat_hos_percent
        let keySeven = selectedSymbol === 'rupee' ? states.holi_doc_fee : states.holi_doc_percent
        let keyEight = selectedSymbol === 'rupee' ? states.holi_hos_fee : states.holi_hos_percent
        let keyNine = selectedSymbol === 'rupee' ? states.eme_doc_fee : states.eme_doc_percent
        let keyTen = selectedSymbol === 'rupee' ? states.eme_hos_fee : states.eme_hos_percent
        let newKeyOne = selectedSymbol === 'rupee' ? states.newDoctorFee : states.newDoctorPercent
        let newKeyTwo = selectedSymbol === 'rupee' ? states.newHospitalFee : states.newHospitalPercent

        let serviceTypeName = ((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'Consultation') ? 'Consultation' : this.state.service_type
        return (
            <Box component={'div'}>
                {this.state.showCode ?
                    <Box component={'div'} padding={'0.5vw'} paddingBottom={'0.5vw'} display={'flex'}>
                        {this.inputTextBox('service_type', serviceTypeName, 'Service Type *', '16vw', '0.5vw', null, null, ((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'Consultation') ? true : false)}
                    </Box> : null}
                <Box component={'div'} padding={'0.5vw'} borderBottom={'1px solid lightgray'} paddingBottom={'1vw'} display={'flex'}>
                    {/* {(((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'OP Procedures')) ?
                        this.inputTextBox('service_name', this.state.service_name, 'Service Name *', '16vw', '0.5vw')
                        : */}
                    {this.state.showCode ? null :
                        this.inputTextBox('service_type', serviceTypeName, 'Service Type *', '16vw', '0.5vw', null, null, ((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'Consultation') ? true : false)
                    }
                    <Autocomplete
                        clearIcon
                        size='small'
                        sx={{ width: '16vw', mr: '0.5vw' }}
                        options={this.state.doctorList}
                        getOptionLabel={(options) => typeof (options) === 'string' ? options : options.doctor_name}
                        value={this.state.doctor_name}
                        onChange={(e, newValue) => { this.setState({ doctor_name: newValue }) }}
                        renderInput={(params) => <TextField {...params} label={'Select Doctor Name *'} sx={{ backgroundColor: 'white' }} type='search' />}
                        renderOption={(props, option) => (
                            <li {...props} key={option?.doctor_id}>
                                {option?.doctor_name}
                            </li>
                        )}
                    />
                    {/* } */}
                    {this.state.showCode ?
                        <TextField
                            size='small'
                            sx={{ width: '16vw' }}
                            autoComplete='off'
                            value={this.state.opServiceCodeCon}
                            onChange={(e) => {
                                let value = e.target.value
                                if (value?.length <= OP_IP_CODE_LENGTH || value === '') {
                                    this.setState({ opServiceCodeCon: value })
                                }
                            }}
                            label='Service Code'
                        /> : null}

                </Box>
                <Box component={'div'} padding={'0.5vw'} display={'flex'} justifyContent={'flex-end'} mr={'1vw'}>
                    <Typography component={'div'} display={'flex'} alignItems={'center'} color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>
                        Select Type :
                        <Select
                            size='small'
                            sx={{ width: '5vw', height: '2vw', ml: '1vw' }}
                            value={this.state.selectedSymbol}
                            onChange={(e) => { this.onChangeType(e) }}
                        >
                            <MenuItem value={'rupee'}>{CurrencySymbol}</MenuItem>
                            <MenuItem value={'percentage'}>%</MenuItem>
                        </Select>
                    </Typography>
                </Box>
                {(((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'OP Procedures')) ?
                    <Box component={'div'} padding={'0.5vw'} borderBottom={'1px solid lightgray'}>
                        {/* Commented for Not Needed */}
                        {/* <Box component={'div'}>
                            {this.state.newSelectedUnit === 'nos' ?
                                <TextField
                                    size='small'
                                    disabled
                                    sx={{ width: '16vw', marginLeft: '0.5vw', height: '2.5vw' }}
                                    value={"1"}
                                /> :
                                <TimePicker
                                    format={'HH:mm'}
                                    style={{ width: '16vw', marginLeft: '0.5vw', height: '2.5vw' }}
                                    disabled={this.state.newSelectedUnit === 'nos'}
                                    value={this.state.newTimeTaken ? moment(this.state.newTimeTaken, 'hh:mm') : null}
                                    onChange={(time, timeString) => this.setState({ newTimeTaken: timeString })}
                                />
                            }
                            <FormControl sx={{ width: '16vw', ml: '0.5vw' }} size='small'>
                                <InputLabel>Unit *</InputLabel>
                                <Select
                                    label='unit'
                                    value={this.state.newSelectedUnit}
                                    onChange={(e) => { this.setState({ newSelectedUnit: e.target.value, newTimeTaken: null }) }}
                                >
                                    <MenuItem value={'nos'}>Nos</MenuItem>
                                    <MenuItem value={'hrs'}>Hrs</MenuItem>
                                </Select>
                            </FormControl>
                        </Box> */}
                        <Box component={'div'}>
                            <Box mb={'1vw'}>
                                <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'} mb={'0.5vw'}>Service Amount</Typography>
                                {this.inputTextBox('newServiceAmount', this.state.newServiceAmount, "Service Amount *", '15vw')}
                            </Box>
                            <Box>
                                {this.inputTextBox('newDoctorFee', newKeyOne, "Doctor Fee", '16vw', '1vw', true, null, newDoctor)}
                                {this.inputTextBox('newHospitalFee', newKeyTwo, "Hospital Fee", '16vw', null, true, null, newDoctor)}
                            </Box>
                        </Box>
                    </Box>
                    :
                    <Box component={'div'} padding={'0.5vw'} borderBottom={'1px solid lightgray'}>
                        <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
                            <Box mr={'0.2vw'}>
                                <Box display={'flex'} mb={'0.5vw'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Box><Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>New Patient Fee</Typography></Box>
                                    <Box>
                                        {this.inputTextBox('patTotalAmt', states.new_pat_total_amt, "Total Amount *", '8vw', null, null, '0.5vw')}
                                    </Box>
                                </Box>
                                <Box>
                                    {this.inputTextBox('patDoctorFee', keyOne, "Doctor Fee", '8vw', '0.2vw', true, null, newPatient)}
                                    {this.inputTextBox('patHospitalFee', keyTwo, "Hospital Fee", '8vw', null, true, null, newPatient)}
                                </Box>
                            </Box>
                            <Box mb={'1vw'}>
                                <Box display={'flex'} mb={'0.5vw'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Box><Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Review Patient Fee</Typography></Box>
                                    <Box>
                                        {this.inputTextBox('revTotalAmt', states.rev_pat_total_amt, "Total Amount *", '8vw', null, null, '0.5vw')}
                                    </Box>
                                </Box>
                                <Box>
                                    {this.inputTextBox('revDoctorFee', keyThree, "Doctor Fee", '8vw', '0.2vw', true, null, revPatient)}
                                    {this.inputTextBox('revHospitalFee', keyFour, "Hospital Fee", '8vw', null, true, null, revPatient)}
                                </Box>
                            </Box>
                            <Box>
                                <Box display={'flex'} mb={'0.5vw'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Box><Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Old Patient Fee</Typography></Box>
                                    <Box>
                                        {this.inputTextBox('oldTotalAmt', states.old_pat_total_amt, "Total Amount *", '8vw', null, null, '0.5vw')}
                                    </Box>
                                </Box>
                                <Box>
                                    {this.inputTextBox('oldDoctorFee', keyFive, "Doctor Fee", '8vw', '0.2vw', true, null, oldPatient)}
                                    {this.inputTextBox('oldHospitalFee', keySix, "Hospital Fee", '8vw', null, true, null, oldPatient)}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }

                <Box component={'div'} padding={'0.5vw'} borderBottom={'1px solid lightgray'}>
                    <Box mr={'0.2vw'} mb={'1vw'}>
                        <Box display={'flex'} mb={'0.5vw'} alignItems={'center'} justifyContent={'space-between'}>
                            <Box><Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Holiday Fee </Typography></Box>
                            {this.inputTextBox('holiTotalAmt', states.holi_total_amt, "Total Amount", '16vw', '0.3vw', null, null, null)}
                        </Box>
                        <Box ml={"0.5vw"}>
                            {this.inputTextBox('holiDoctorFee', keySeven, "Doctor Fee", '16vw', '0.6vw', true, null, holiday)}
                            {this.inputTextBox('holiHospitalFee', keyEight, "Hospital Fee", '16vw', null, true, null, holiday)}
                        </Box>
                    </Box>
                    <Box mb={'0.5vw'}>
                        <Box display={'flex'} mb={'0.5vw'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Emergency Fee</Typography>
                        </Box>

                        <Box component={'div'} className="eMed_DisSum_Lay_Spec" margin={"0.5vw"} sx={{ display: "flex", flexDirection: "column" }}>
                            {this.state.timeSlots.map((slot, index) => (
                                <Box sx={{ position: "relative" }}>
                                    <Box key={index} display="flex" flexDirection='column' mb={'1vw'} sx={{ borderBottom: index === this.state.timeSlots.length - 1 ? "none" : "1px solid #c6c3c3" }}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker
                                                        label="Start Time"
                                                        value={slot.start_time ? dayjs(slot.start_time, 'hh:mm A') : null}
                                                        onChange={(newValue) => this.handleTimeSlotChange(index, 'start_time', newValue)}
                                                        renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} size='small' style={{ width: "16vw", marginRight: "0.6vw", height: "2.5vw" }} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                            <div>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker
                                                        label="End Time"
                                                        value={slot.end_time ? dayjs(slot.end_time, 'hh:mm A') : null}
                                                        onChange={(newValue) => this.handleTimeSlotChange(index, 'end_time', newValue)}
                                                        renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} size='small' style={{ width: "16vw", height: "2.5vw" }} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "0.5vw", mb: index === this.state.timeSlots.length - 1 ? "0.5vw" : "1vw" }}>
                                            {this.inputTextBox('emeTotalAmt', slot?.eme_doc_total_amt, "Total Amount *", '10.5vw', "0.3vw", false, null, null, index)}
                                            {this.inputTextBox('emeDoctorFee', (this.state.selectedSymbol === 'rupee' ? slot?.eme_doc_fee : slot?.eme_doc_percent), "Doctor Fee", '10.5vw', '0.3vw', true, null, slot.eme_doc_total_amt === '', index)}
                                            {this.inputTextBox('emeHospitalFee', (this.state.selectedSymbol === 'rupee' ? slot?.eme_hos_fee : slot?.eme_hos_percent), "Hospital Fee", '11vw', null, true, null, slot.eme_doc_total_amt === '', index)}
                                        </Box>
                                    </Box>
                                    <div className="eMed_cancel" style={{ color: "white", backgroundColor: "red", position: 'absolute', borderRadius: "2vw", marginTop: index === this.state.timeSlots.length - 1 ? "-7.6vw" : "-8.1vw", marginLeft: "32vw", zIndex: 80, width: "1.1vw", height: "1.1vw" }}>
                                        <CloseIcon onClick={() => { this.handleClose(index, "timeSlots", this.state.timeSlots) }} style={{ fontSize: "1.1vw" }} />
                                    </div>
                                </Box>
                            ))}
                            <Box component={'div'}>
                                <Button
                                    className='eMed_New_Ip_Btn'
                                    startIcon={<AddCircle />}
                                    disabled={!this.AddButtonEnabled()}
                                    onClick={() => { this.addEmergencyFee() }}
                                >
                                    Add Addtional Emergency Fee
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {this.renderGstTextBox()}
                <Box component={'div'} padding={'0.5vw'} borderBottom={'1px solid lightgray'} display={'flex'}>
                    <Box flex={0.8}>
                        <FormControlLabel control={<Checkbox size='small' checked={this.state.isEditUnit} onChange={(e) => this.checkboxHandler(e, 'isEditUnit')} />} label="Edit Unit" />
                        <FormControlLabel control={<Checkbox size='small' checked={this.state.isEditAmount} onChange={(e) => this.checkboxHandler(e, 'isEditAmount')} />} label="Edit Amount" />
                        <FormControlLabel control={<Checkbox size='small' checked={this.state.isEditDiscount} onChange={(e) => this.checkboxHandler(e, 'isEditDiscount')} />} label="Edit Discount" />
                    </Box>
                    <Box flex={0.2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'1vw'}>
                        <Button
                            sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                            onClick={() => {
                                this.setState({ conEdit: false })
                                this.CheckTimeDuplication(true)
                            }}
                            variant='contained'>Add</Button>
                    </Box>
                </Box>
                {this.renderGStTxt()}
                <Box component={'div'} maxHeight={'35vh'}>
                    <Table size='small' stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: Colors.Background }}>
                                    <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Doctor Name</Typography>
                                </TableCell>
                                {(((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'OP Procedures')) ?
                                    <>
                                        {/* <TableCell sx={{ backgroundColor: Colors.Background }}>
                                                <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Unit</Typography>
                                            </TableCell> */}
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Service Amount</Typography>
                                        </TableCell></> :
                                    <>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>New</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Review</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Old</Typography>
                                        </TableCell></>

                                }
                                <TableCell sx={{ backgroundColor: Colors.Background }} align='center'>
                                    <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Action</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.serviceNameList?.length > 0 ? this.state.serviceNameList?.map((item, index) => (
                                item?.service_charges?.map((data) => (
                                    <TableRow key={index}>
                                        <TableCell>{item?.service_name ? item?.service_name?.length > 15 ?
                                            <Tooltip placement='top' title={item?.service_name}><div>{item?.service_name.slice(0, 10) + '...'}</div></Tooltip> :
                                            <div>{item?.service_name}</div> : '-'}
                                        </TableCell>
                                        {(((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'OP Procedures')) ?
                                            <>
                                                {/* <TableCell>{item?.unit ? item?.unit === 'hrs' ? "Hrs" : item?.unit : 0}</TableCell> */}
                                                <TableCell>{data?.service_fee?.total_amt ? AmountFormat((item?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(data?.service_fee?.total_amt, item?.gst_percentage) : data?.service_fee?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell>
                                            </> : <>
                                                <TableCell>{data?.new_patient?.total_amt ? AmountFormat((item?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(data?.new_patient?.total_amt, item?.gst_percentage) : data?.new_patient?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell>
                                                <TableCell>{data?.review_patient?.total_amt ? AmountFormat((item?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(data?.review_patient?.total_amt, item?.gst_percentage) : data?.review_patient?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell>
                                                <TableCell>{data?.old_patient?.total_amt ? AmountFormat((item?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(data?.old_patient?.total_amt, item?.gst_percentage) : data?.old_patient?.total_amt)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell></>
                                        }

                                        <TableCell>
                                            <Box display={'flex'} justifyContent={'center'}>
                                                <Button
                                                    className='eMed_Config_Btn'
                                                    sx={{ width: '1.8vw', height: '1.7vw' }}
                                                    onClick={() => this.serviceListEdit(item, data, index)}
                                                >
                                                    <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.6vw'} width={'1.6vw'} alt='edit' />
                                                </Button>
                                                <Button
                                                    className='eMed_Config_Btn'
                                                    sx={{ width: '1.8vw', height: '1.7vw' }}
                                                >
                                                    <Box
                                                        onClick={() => {
                                                            if (!this.state.conEdit) {
                                                                this.state.serviceNameList.splice(index, 1)
                                                                this.setState({ serviceNameList: this.state.serviceNameList })
                                                            } else {
                                                                this.errorMessage("Service line item in edit")
                                                            }
                                                        }}
                                                        component={'img'} src={ImagePaths.Delete.default} height={'1.6vw'} width={'1.6vw'} alt='delete' />
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )) : <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell>No Records Found</TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>}
                        </TableBody>
                    </Table>
                </Box>

            </Box>
        )
    }

    clearOtherService = () => {
        try {
            this.setState({
                service_name: '',
                selectedTime: null,
                selectedUnit: 'nos',
                service_amt: '',
                holiday_amt: '',
                emergency_amt: '',
                editSelectedIndex: null,
                otherServiceEdit: false,
                isEditAmount: false,
                isEditDiscount: false,
                isEditUnit: false,
                serviceConfigData: JSON.parse(this.state.duplicateServiceData),
                selectedId: null,
                otherEdit: false,
                minimum_amt: '',
                selectedMinTime: null,
                opServiceCodeOth: '',
                gstPercentage: '',
                otherTimeSlots: JSON.parse(this.state.duplicateOtherTimeSlots)
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    checkOtherCategoryDuplicate = () => {
        try {
            let message = null;
            let unit = this.state.selectedUnit === 'nos' ? 1 : this.state.selectedUnit
            this.state.otherServiceList.forEach((element, index) => {
                element.service_charges.forEach(amt => {
                    if (index !== this.state.editSelectedIndex) {
                        if (element.service_name === this.getCapitalize(this.state.service_name) && element.unit === unit && amt.service_amount === this.state.service_amt) {
                            message = "Service Name,Unit and Amount Already Exist"
                        } else if (element.service_name === this.getCapitalize(this.state.service_name)) {
                            message = "Service Name Already Exists"
                        } else if (element.service_name === this.getCapitalize(this.state.service_name) && element.unit === unit) {
                            message = "Service Name and Unit Already Exist"
                        } else if (element.service_name === this.getCapitalize(this.state.service_name) && amt.service_amount === this.state.service_amt) {
                            message = "Service Name and Amount Already Exist"
                        } else if (this.state.opServiceCodeOth !== '' && element.op_service_code?.toLocaleLowerCase() === CommonValidation.removeSpace(this.state.opServiceCodeOth)?.toLocaleLowerCase()) {
                            message = 'Service Code Already Exist'
                        }
                    }
                })
            })
            return message;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    updateOrInsertList = (array, newValue, selectedIndex) => {
        try {
            if (array.length === 0) {
                return [newValue]
            } else {
                const obj = array.find((item, index) => index === selectedIndex)
                if (!obj) {
                    return [...array, newValue]
                } else {
                    if (JSON.stringify(obj) === JSON.stringify(newValue)) {
                        return [...array.filter((object, index) => index !== selectedIndex), newValue]
                    } else {
                        return [...array.filter((object, index) => index !== selectedIndex), newValue]
                    }
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    addOtherServiceListData = () => {
        try {
            let duplicate = this.checkOtherCategoryDuplicate()
            if (this.state.other_service_type !== '' && this.state.service_name !== '' && this.state.service_amt !== '' && (this.state.selectedUnit === 'hrs' ? this.state.minimum_amt !== '' : true)) {
                if ((this.state.selectedUnit === 'hrs' ? this.state.selectedTime !== null : "1") && (this.state.selectedUnit === 'hrs' ? (this.state.selectedMinTime !== null && this.state.minimum_amt) : "1")) {
                    if (duplicate === null) {
                        let { otherServiceList } = this.state
                        let service_list = {}
                        let serviceCatageoryId = this.state.categoryData?.find((item) => item?.catagory_name === this.state.serviceCategory)
                        let categoryId = this.state.serviceCategory?.id ? this.state.serviceCategory?.id :
                            this.state.editServiceCategoryId ? this.state.otherCategoryId :
                                serviceCatageoryId?.id ? serviceCatageoryId?.id : null
                        service_list = {
                            "op_service_code": this.state.opServiceCodeOth ? CommonValidation.removeSpace(this.state.opServiceCodeOth) : '',
                            "service_catagory_id": categoryId,
                            "service_name": this.getCapitalize(this.state.service_name),
                            // "service_type": this.state.other_service_type ? this.state.other_service_type : '',
                            "doctor_id": null,
                            "doctor_name": null,
                            "is_edit_unit": this.state.isEditUnit,
                            "is_edit_amount": this.state.isEditAmount,
                            "is_edit_discount": this.state.isEditDiscount,
                            "time_taken": this.state.selectedTime,
                            "unit": this.state.selectedUnit === 'nos' ? 1 : this.state.selectedUnit,
                            "gst_percentage": this.state.is_gst_enabled ? (+this.state.gstPercentage || 0) : 0,
                            "service_charges": [{
                                "service_amount": this.state.service_amt,
                                "holiday_fee": this.state.holiday_amt,
                                "emergency_fee": this.state.otherTimeSlots,
                                "minimum_amount": this.state.minimum_amt
                            }],
                            "additional_charges": [{
                                "holiday_fee": this.state.holiday_amt,
                                "emergency_fee": this.state.otherTimeSlots
                            }],
                            "id": this.state.selectedId,
                            "amount_type": null,
                            'minimum_time': this.state.selectedMinTime ? this.state.selectedMinTime == 60 ? "01:00:00" : `00:${this.state.selectedMinTime}` : '00:00'
                        }

                        // if (this.state.isEdit) { service_list['id'] = this.state.selectedId }
                        let data = this.updateOrInsertList(otherServiceList, service_list, this.state.editSelectedIndex)
                        this.setState({ otherServiceList: data }, () => {
                            this.clearOtherService()
                        })
                    } else {
                        this.errorMessage(duplicate)
                    }
                } else {
                    this.errorMessage("Select Times")
                }
            } else {
                this.errorMessage("Enter All Mandatory Fields")
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onOtherServiceEdit = (item, data, index) => {
        try {
            const timeSlots = data?.emergency_fee.length > 0 ? data?.emergency_fee?.map((fee) => ({
                emergency_amt: fee.emergency_amt ? fee.emergency_amt : "",
                start_time: fee.start_time ? fee.start_time : null,
                end_time: fee.end_time ? fee.end_time : null,
            })) : JSON.parse(this.state.duplicateOtherTimeSlots);
            this.setState({
                editSelectedIndex: index,
                otherServiceEdit: true,
                service_name: item?.service_name,
                selectedTime: item?.time_taken ? item?.time_taken : null,
                selectedUnit: (item?.unit === "1" || item?.unit === 1) ? 'nos' : item?.unit,
                service_amt: data?.service_amount,
                holiday_amt: data?.holiday_fee,
                // emergency_amt: data?.emergency_fee,
                otherTimeSlots: timeSlots,
                selectedId: item?.id,
                otherCategoryId: item?.service_catagory_id,
                otherEdit: true,
                isEditAmount: item?.is_edit_amount,
                isEditDiscount: item?.is_edit_discount,
                isEditUnit: item?.is_edit_unit,
                minimum_amt: data?.minimum_amount,
                selectedMinTime:item?.minimum_time ? item?.minimum_time === '01:00:00' ? 60 : item?.minimum_time?.split(':')[1] : null,
                gstPercentage: this.state.is_gst_enabled ? item?.gst_percentage : 0,
                opServiceCodeOth:item?.op_service_code ? item?.op_service_code : ""
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    AddButtonEnabled = () => {
        return this.state.timeSlots.every(slot => slot.start_time && slot.end_time && slot.eme_doc_total_amt);
    };

    ServiceAddButtonEnabled = () => {
        return this.state.otherTimeSlots.every(slot => slot.start_time && slot.end_time && slot.emergency_amt);
    };

    addOtherEmergencyFee = () => {
        const { otherTimeSlots } = this.state;
        const lastSlot = otherTimeSlots[otherTimeSlots.length - 1];

        if (lastSlot.start_time && lastSlot.end_time) {
            const isOverlap = otherTimeSlots.slice(0, -1).some(slot => {
                const start = moment(slot.start_time, 'hh:mm a');
                const end = moment(slot.end_time, 'hh:mm a');
                if (start.isAfter(end)) {
                    end.add(1, 'days');
                }

                const lastStart = moment(lastSlot.start_time, 'hh:mm a');
                const lastEnd = moment(lastSlot.end_time, 'hh:mm a');
                if (lastStart.isAfter(lastEnd)) {
                    lastEnd.add(1, 'days');
                }

                return (
                    lastStart.isBetween(start, end, null, '[)') ||
                    lastEnd.isBetween(start, end, null, '(]')
                );
            });

            if (isOverlap) {
                this.errorMessage("Time slot overlaps with an existing slot. Please adjust the time.");
                return;
            }
        }

        const newTimeSlot = { start_time: null, end_time: null, emergency_amt: '' };
        this.setState({ otherTimeSlots: [...otherTimeSlots, newTimeSlot] });
    }

    addEmergencyFee = () => {
        const { timeSlots } = this.state;
        const lastSlot = timeSlots[timeSlots.length - 1];

        if (lastSlot.start_time && lastSlot.end_time) {
            const isOverlap = timeSlots.slice(0, -1).some(slot => {
                const start = moment(slot.start_time, 'hh:mm a');
                const end = moment(slot.end_time, 'hh:mm a');
                if (start.isAfter(end)) {
                    end.add(1, 'days');
                }

                const lastStart = moment(lastSlot.start_time, 'hh:mm a');
                const lastEnd = moment(lastSlot.end_time, 'hh:mm a');
                if (lastStart.isAfter(lastEnd)) {
                    lastEnd.add(1, 'days');
                }

                return (
                    lastStart.isBetween(start, end, null, '[)') ||
                    lastEnd.isBetween(start, end, null, '(]')
                );
            });

            if (isOverlap) {
                this.errorMessage("Time slot overlaps with an existing slot. Please adjust the time.");
                return;
            }
        }
        const newTimeSlot = {
            start_time: null,
            end_time: null, eme_doc_total_amt: '',
            eme_doc_fee: '', eme_hos_fee: '',
            eme_doc_percent: '', eme_hos_percent: '',
        };
        this.setState({
            timeSlots: [...this.state.timeSlots, newTimeSlot]
        })
        this.setState({ timeSlots: [...timeSlots, newTimeSlot] });
    }

    isInvalidEmergency = (List) => {
        if(List.some((list)=>(list?.start_time && list?.end_time && !list?.emergency_amt && !list?.eme_doc_total_amt))){
            return true
        }else{
            return false
        }
    }

    parseTime = (time) => {
        const [hourMinute, period] = time.split(" ");
        const [hours, minutes] = hourMinute.split(":").map(Number);
        let totalMinutes = hours * 60 + minutes;
        if (period === "PM" && hours !== 12) {
            totalMinutes += 12 * 60; // Convert PM to 24-hour format
        }
        if (period === "AM" && hours === 12) {
            totalMinutes -= 12 * 60; // Convert midnight to 0-hour
        }
        return totalMinutes;
    };
    
    hasOverlappingTimes = (sessions) => {
        const timeRanges = sessions.map(session => ({
            start: this.parseTime(session.start_time),
            end: this.parseTime(session.end_time)
        }));
    
        for (let i = 0; i < timeRanges.length; i++) {
            for (let j = i + 1; j < timeRanges.length; j++) {
                if (
                    timeRanges[i].start <= timeRanges[j].end &&
                    timeRanges[i].end >= timeRanges[j].start
                ) {
                    return true; // Overlap found
                }
            }
        }
        return false; // No overlaps
    };
    

    CheckTimeDuplication = (fromTimeslot) => {
        const { timeSlots } = this.state;
        const { otherTimeSlots } = this.state;

        const lastSlot = fromTimeslot ? timeSlots[timeSlots.length - 1] : otherTimeSlots[otherTimeSlots.length - 1]

        if (lastSlot.start_time && lastSlot.end_time) {
            let isOverlap = this.hasOverlappingTimes(fromTimeslot ? timeSlots : otherTimeSlots)
            if (isOverlap) {
                this.errorMessage("Time slot overlaps with an existing slot. Please adjust the time.");
                return;
            } else {
                if (this.isInvalidEmergency(fromTimeslot ? timeSlots : otherTimeSlots) && (fromTimeslot ? timeSlots?.length : otherTimeSlots?.length)) {
                    this.errorMessage("Enter Emergency Amount");
                } else if (fromTimeslot) {
                    this.addServiceListData()
                } else {
                    this.addOtherServiceListData()
                }
            }
        } else {
            if (this.isInvalidEmergency(fromTimeslot ? timeSlots : otherTimeSlots) && (fromTimeslot ? timeSlots?.length : otherTimeSlots?.length)) {
                this.errorMessage("Enter Emergency Amount");
            } else if (fromTimeslot) {
                this.addServiceListData()
            } else {
                this.addOtherServiceListData()
            }
        }
    }

    handleClose = (index, statekey, data) => {
        let states = this.state
        if (data.length > 1) {
            states[statekey] = data.filter((_, i) => i !== index);
        } else {
            if (statekey === "timeSlots") {
                states[statekey] = [
                    {
                        start_time: null,
                        end_time: null,
                        eme_doc_total_amt: '',
                        eme_doc_fee: '',
                        eme_hos_fee: '',
                        eme_doc_percent: '',
                        eme_hos_percent: '',
                    }
                ]
            } else {
                states[statekey] = [
                    {
                        start_time: null,
                        end_time: null,
                        emergency_amt: '',
                    }
                ]
            }
        }
        this.setState({
            states
        });
    }

    renderOtherCategoryScreen = () => {
        return (
            <Box component={'div'}>
                <Box component={'div'} padding={'0.5vw'} borderBottom={'1px solid lightgray'}>
                    {this.inputTextBox('other_service_type', this.state.other_service_type, "Service Type *", '32.5vw')}
                </Box>
                <Box component={'div'} padding={'0.5vw'} borderBottom={'1px solid lightgray'}>
                    {this.state.showCode ?
                        <Box component={'div'} mb={'0.5vw'}>
                            {this.inputTextBox('service_name', this.state.service_name, "Service Name *", '15vw')}
                            <TextField
                                size='small'
                                sx={{ width: '15vw', ml: '0.5vw' }}
                                value={this.state.opServiceCodeOth}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (value?.length <= OP_IP_CODE_LENGTH || value === '') {
                                        this.setState({ opServiceCodeOth: value })
                                    }
                                }}
                                label='Service Code'
                                autoComplete='off'
                            />
                        </Box> : null}
                    <Box component={'div'}>
                        {this.state.showCode ? null : this.inputTextBox('service_name', this.state.service_name, "Service Name *", '15vw', '0.5vw')}
                        {this.state.selectedUnit === 'nos' ?
                            <TextField
                                value={this.state.selectedUnit === 'nos' ? this.state.selectedUnitCount : this.state.selectedTime}
                                disabled
                                size='small'
                                sx={{ width: '8.2vw', height: '2.5vw' }}
                            /> :
                            <AntdTimePicker
                                format={'HH:mm'}
                                style={{ width: '8.2vw', height: '2.5vw' }}
                                disabled={this.state.selectedUnit === 'nos'}
                                value={this.state.selectedTime ? moment(this.state.selectedTime, 'hh:mm') : null}
                                onChange={(time, timeString) => this.setState({ selectedTime: timeString })}
                            />
                        }
                        <FormControl sx={{ width: '8.2vw', ml: '0.5vw' }} size='small'>
                            <InputLabel>Unit *</InputLabel>
                            <Select
                                label='unit'
                                value={this.state.selectedUnit}
                                onChange={(e) => { this.setState({ selectedUnit: e.target.value, selectedTime: null, selectedMinTime: null }) }}
                            >
                                <MenuItem value={'nos'}>Nos</MenuItem>
                                <MenuItem value={'hrs'}>Hrs</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {this.state.selectedUnit !== 'nos' ?
                        <div style={{ marginTop: '0.5vw' }}>{this.inputTextBox('service_amt', this.state.service_amt, "Price Per Unit *", '15vw')}</div> : null
                    }
                    <Box component={'div'} mt={'0.5vw'}>
                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'} mb={'0.5vw'}>{this.state.selectedUnit === 'nos' ? 'Service Amount' : 'Interval Time and Amount'}</Typography>
                        {this.state.selectedUnit === 'nos' ?
                            this.inputTextBox('service_amt', this.state.service_amt, "Service Amount *", '15vw')
                            :
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Autocomplete
                                    disableClearable
                                    size='medium'
                                    value={this.state.selectedMinTime}
                                    options={this.TimeListArray?.map((item) => item)}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            selectedMinTime: newValue,
                                        })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ width: '15vw', marginRight: '0.5vw' }}
                                            size='small'
                                            label={'Select Interval Time *'}
                                        />
                                    )} />
                                {this.inputTextBox('minimum_amt', this.state.minimum_amt, "Interval Amount *", '15vw')}

                            </div>
                        }
                    </Box>
                </Box>
                <Box component={'div'} padding={'0.5vw'} borderBottom={'1px solid lightgray'}>
                    <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'} mb={'0.5vw'}>Additional Amount</Typography>
                    <Box component={'div'}>
                        <Box flex={0.5}>
                            <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'} mb={'0.5vw'}>Holiday Fee</Typography>
                            {this.inputTextBox('holidayAmt', this.state.holiday_amt, "Holiday Amount", "15.5vw")}
                        </Box>
                        <Box flex={0.5} mt={"0.5vw"}>
                            <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'} mb={'0.5vw'}>Emergency Fee</Typography>
                            {this.state.otherTimeSlots.map((slot, index) => (
                                <Box sx={{ position: "relative" }}>
                                    <Box key={index} display="flex" alignItems="center" justifyContent="center" mb={index === this.state.otherTimeSlots.length - 1 ? "0.5vw" : '1vw'} mt={"0.5vw"}>
                                        <div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    label="Start Time"
                                                    value={slot.start_time ? dayjs(slot.start_time, 'hh:mm A') : null}
                                                    onChange={(newValue) => this.handleOtherTimeSlotChange(index, 'start_time', newValue)}
                                                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} size='small' style={{ width: "10vw", marginRight: "0.35vw", height: "2.5vw" }} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    label="End Time"
                                                    value={slot.end_time ? dayjs(slot.end_time, 'hh:mm A') : null}
                                                    onChange={(newValue) => this.handleOtherTimeSlotChange(index, 'end_time', newValue)}
                                                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} size='small' style={{ width: "10vw", marginRight: "0.35vw", height: "2.5vw" }} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        {this.inputTextBox('emergencyAmt', slot?.emergency_amt, "Emergency Amount", "12.5vw", null, null, null, null, index)}
                                    </Box>
                                    <div className="eMed_cancel" style={{ color: "white", backgroundColor: "red", position: 'absolute', borderRadius: "2vw", marginTop: index === this.state.otherTimeSlots.length - 1 ? "-3.5vw" : "-4vw", marginLeft: "32.5vw", zIndex: 80, width: "1.1vw", height: "1.1vw" }}>
                                        <CloseIcon onClick={() => { this.handleClose(index, "otherTimeSlots", this.state.otherTimeSlots) }} style={{ fontSize: "1.1vw" }} />
                                    </div>
                                </Box>
                            ))}
                            <Box component={'div'}>
                                <Button
                                    className='eMed_New_Ip_Btn'
                                    startIcon={<AddCircle />}
                                    disabled={!this.ServiceAddButtonEnabled()}
                                    onClick={() => { this.addOtherEmergencyFee() }}
                                >
                                    Add Addtional Emergency Fee
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {this.renderGstTextBox()}
                <Box component={'div'} padding={'0.5vw'} borderBottom={'1px solid lightgray'} display={'flex'}>
                    <Box flex={0.8}>
                        <FormControlLabel control={<Checkbox size='small' checked={this.state.isEditUnit} onChange={(e) => this.checkboxHandler(e, 'isEditUnit')} />} label="Edit Unit" />
                        <FormControlLabel control={<Checkbox size='small' checked={this.state.isEditAmount} onChange={(e) => this.checkboxHandler(e, 'isEditAmount')} />} label="Edit Amount" />
                        <FormControlLabel control={<Checkbox size='small' checked={this.state.isEditDiscount} onChange={(e) => this.checkboxHandler(e, 'isEditDiscount')} />} label="Edit Discount" />
                    </Box>
                    <Box flex={0.2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'1vw'}>
                        <Button sx={{ width: '5vw', height: '2vw' }}
                            onClick={() => {
                                this.setState({ otherEdit: false })
                                this.CheckTimeDuplication(false)
                            }} variant='contained'>Add</Button>
                    </Box>
                </Box>
                {this.renderGStTxt()}
                <Box component={'div'} maxHeight={'35vh'}>
                    {this.state.otherServiceList?.length > 0 ?
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Tooltip placement='top' title={'Service Name'} arrow><Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>{"S.Name"}</Typography></Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Unit</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Tooltip placement='top' title={'Service Amount'} arrow><Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>{"S.Amt"}</Typography></Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Holiday</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Emergency</Typography>
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Action</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.otherServiceList?.map((item, index) => (
                                    item?.service_charges?.map((val) => (
                                        <TableRow key={index}>
                                            <TableCell>{item?.service_name ? item?.service_name?.length > 5 ?
                                                <Tooltip placement='top' title={item?.service_name}><Box>{(item?.service_name)?.slice(0, 5) + '...'}</Box></Tooltip> :
                                                item?.service_name
                                                : '-'}</TableCell>
                                            <TableCell>{item?.unit ? item?.unit === 'hrs' ? `${item?.time_taken ? item?.time_taken?.slice(0, 5) : '-'} Hrs` : item?.unit : '-'}</TableCell>
                                            <TableCell>{val?.service_amount ? AmountFormat((item?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(val?.service_amount , item?.gst_percentage) : val?.service_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell>
                                            <TableCell>{val?.holiday_fee ? AmountFormat((item?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(val?.holiday_fee , item?.gst_percentage) : val?.holiday_fee)?.replace(`${CurrencySymbol}`, "") : "0.00"}</TableCell>
                                            <TableCell>
                                                <Box component={'div'} id="eMed_Lab_techFont">
                                                    {val?.emergency_fee?.length > 0 ? (
                                                        <>
                                                            {val?.emergency_fee.map((list, index) => {
                                                                if (index < 2) {
                                                                    return (<Tooltip placement="top" title={val?.emergency_fee.map((list) => (
                                                                        <li style={{ fontSize: "0.8vw" }}>
                                                                            {list.start_time && list.end_time ? `${list.start_time} to ${list.end_time} - ${list?.emergency_amt ? AmountFormat(((item?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(list?.emergency_amt , item?.gst_percentage) : list?.emergency_amt))?.replace(`${CurrencySymbol}`, "") : "0.00"}` : "0.00"}
                                                                        </li>))} arrow>
                                                                        <li id="eMed_Lab_techFont">
                                                                            {list.start_time && list.end_time ? `${list.start_time} to ${list.end_time} - ${list?.emergency_amt ? AmountFormat(((item?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(list?.emergency_amt , item?.gst_percentage) : list?.emergency_amt))?.replace(`${CurrencySymbol}`, "") : "0.00"}` : "0.00"}
                                                                        </li>
                                                                    </Tooltip>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                            {val?.emergency_fee?.length > 2 ? (
                                                                <Tooltip placement="top" title={val?.emergency_fee.map((list) => (
                                                                    <li style={{ fontSize: "0.8vw" }}>
                                                                        {list.start_time && list.end_time ? `${list.start_time} to ${list.end_time} - ${list?.emergency_amt ? AmountFormat(((item?.gst_percentage && this.state.is_gst_enabled) ? CommonValidation.CalculateGSTInclude(list?.emergency_amt , item?.gst_percentage) : list?.emergency_amt)) : 0}` : 0}
                                                                    </li>
                                                                ))} arrow >
                                                                    <div id="eMed_Lab_techFont">{"....."}</div>
                                                                </Tooltip>
                                                            ) : ("")}
                                                        </>) : ("-")}
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box display={'flex'} justifyContent={'center'}>
                                                    <Button
                                                        className='eMed_Config_Btn'
                                                        sx={{ width: '1.8vw', height: '1.7vw' }}
                                                        onClick={() => this.onOtherServiceEdit(item, val, index)}
                                                    >
                                                        <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.6vw'} width={'1.6vw'} alt='edit' />
                                                    </Button>
                                                    <Button
                                                        className='eMed_Config_Btn'
                                                        sx={{ width: '1.8vw', height: '1.7vw' }}
                                                        onClick={() => {
                                                            if (!this.state.otherEdit) {
                                                                this.state.otherServiceList.splice(index, 1)
                                                                this.setState({ otherServiceList: this.state.otherServiceList })
                                                            } else {
                                                                this.errorMessage("Service line item in edit")
                                                            }
                                                        }}
                                                    >
                                                        <Box component={'img'} src={ImagePaths.Delete.default} height={'1.6vw'} width={'1.6vw'} alt='delete' />
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ))}
                            </TableBody>
                        </Table> : null}
                </Box>
            </Box>
        )
    }

    renderRightContent = () => {
        return (
            <Box component={'div'}>
                <Box component={'div'} sx={{ backgroundColor: Colors.Background }} display={'flex'} height={'3vw'} alignItems={'center'}>
                    <Box flex={0.5} paddingLeft={'0.5vw'}>
                        <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Service Name & Type Configuration</Typography>
                    </Box>
                    <Box flex={0.5} paddingRight={'1vw'}>
                        <Autocomplete
                            clearIcon
                            size='small'
                            sx={{ width: '16vw' }}
                            options={this.state.categoryData}
                            getOptionLabel={(options) => typeof (options) === 'string' ? options : options.catagory_name}
                            value={this.state.serviceCategory}
                            onChange={(e, newValue) => {
                                this.setState({
                                    serviceCategory: newValue,
                                    service_type: '', other_service_type: "",
                                    serviceNameList: [],
                                    otherServiceList: [],
                                    service_name: '', isEditAmount: false,
                                    service_amt: '', isEditDiscount: false,
                                    hospital_amt: '', isEditUnit: false,
                                    emergency_amt: '', minimum_amt: '',
                                    opServiceCodeCon: '', opServiceCodeOth: '',
                                    timeSlots: JSON.parse(this.state.duplicateTimeSlot),
                                    otherTimeSlots: JSON.parse(this.state.duplicateOtherTimeSlots)
                                })
                            }}
                            renderInput={(params) => <TextField
                                {...params} sx={{ backgroundColor: 'white' }}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        this.setState({ serviceCategory: this.state.categoryData.find((item) => item?.catagory_name === 'Consultation') })
                                    }
                                }}
                            />}
                        />
                    </Box>
                </Box>
                <Box component={'div'} height={'64.8vh'} overflow={'scroll'}>
                    {((((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'Consultation') || ((this.state.serviceCategory?.catagory_name || this.state.serviceCategory) === 'OP Procedures')) ? this.renderConsultionScreen() : this.renderOtherCategoryScreen())}
                </Box>
                <Box component={'div'} sx={{ backgroundColor: Colors.Background }} display={'flex'} justifyContent={'center'} height={'3vw'} alignItems={'center'}>
                    <Button
                        sx={{ width: '5vw', height: '2vw', marginRight: '1vw', textTransform: 'capitalize' }}
                        variant='outlined'
                        onClick={() => {
                            this.setState({
                                serviceNameList: [],
                                otherServiceList: [],
                                service_type: '',
                                isEdit: false,
                                dupliConsultationData: '[]',
                                dupliOtherData: '[]',
                                serviceCategory: this.state.categoryData.find((item) => item?.catagory_name === 'Consultation')
                            })
                            this.clearAllData()
                            this.clearOtherService()
                        }}
                    >Clear</Button>
                    <Button
                        disabled={(this.state.serviceNameList?.length > 0 || this.state.otherServiceList?.length > 0) && CheckAccessFunc("front_office", "Settings", "OP Service Configuration", "Service Name & Type Config", "SubTab")?.editAccess ? false : true}
                        sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                        variant='contained'
                        onClick={() => { this.postOpServiceConfigData() }}
                    >{this.state.isEdit ? "Update" : "Save"}</Button>
                </Box>
            </Box>
        )
    }

    popupClose = () => {
        this.setState({ deletePopup: false })
    }

    render() {
        console.log(this.state);
        
        return (
            <Box component={'div'} display={'flex'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} flex={0.62} padding={'0.5vw'}>
                    <Box component={'div'} border={'1px solid lightgray'} height={'77.5vh'} sx={{ backgroundColor: 'white' }}>
                        {this.renderLeftContent()}
                    </Box>
                </Box>
                <Box component={'div'} flex={0.38} padding={'0.5vw'}>
                    <Box component={'div'} border={'1px solid lightgray'} height={'77.5vh'} sx={{ backgroundColor: 'white' }}>
                        {this.renderRightContent()}
                    </Box>
                </Box>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`Are you Sure to Delete the ${this.state.deleteServiceType} ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deleteOpServiceConfigData.bind(this)}
                    DeleteNotify={"It will affect the entire Configuration"}
                /> : null}
            </Box>
        )
    }
}
